import React from "react";
import { Row, Grid, Card, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../../components/menu-button";
import AdminLabel from "../../../components/admin/admin-label";
import AppLayoutAdmin from "../../../layout/app-layout-admin";

const { useBreakpoint } = Grid;

const CarRentAdminPage = ({ user }) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = React.useState("run");

  return (
    <AppLayoutAdmin currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      <AdminLabel screens={screens} />
      <MenuButton screens={screens} navigate={navigate} isCarRent={true} />
      {/* <div style={{ backgroundColor: "#150E6E", padding: "24px" }}> */}
      {/* <Row gutter={[16, 16]} align="middle">
          <CategoryButton activeButton={activeButton} />
          <FormControl screens={screens} />
        </Row> */}
      {/* </div> */}
      {/* <RunningEventList screens={screens} /> */}
      <div
        style={{
          backgroundColor: "#5E72EB",
          padding: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "300px",
        }}
      >
        <Card
          style={{ maxWidth: 400, width: "100%", textAlign: "center", backgroundColor: "#FFFFFF" }}
          bordered={false}
        >
          <Typography.Title level={1} style={{ color: "#5E72EB" }}>
            Coming Soon...
          </Typography.Title>
        </Card>
      </div>
    </AppLayoutAdmin>
  );
};

export default CarRentAdminPage;
