import React from "react";
import { Form, Input, Button, Row, Col, Typography, Space, Card, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../../services/fetch-events";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

const { Title, Text } = Typography;

const LoginAdminForm = ({ language }) => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const userLogin = await signInWithEmailAndPassword(auth, values.email, values.password); // Corrected to values.email

      const userData = await loginAdmin(values.email, values.password);

      if (userData) {
        navigate(`/${language}/event/run`);
      } else {
        await auth.signOut();
      }
    } catch (error) {
      console.error("Login failed:", error); // Optional: Add error handling
    }
  };

  return (
    <Row justify="center" align="middle">
      <Col>
        <Card
          size="small"
          style={{
            maxWidth: "400px",
            padding: "40px 20px",
            borderRadius: "60px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            margin: "20px 0px 20px 0px",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <Typography.Title level={2} style={{ color: "#5E6DB6", margin: 0, textAlign: "center" }}>
              Seller Center
            </Typography.Title>
            <Row align="middle">
              <Col span={8}>
                <Image src="/paipao-logo.png" preview={false} width="80%" />
              </Col>
              <Col span={16}>
                <Title level={5} style={{ margin: 0 }}>
                  เข้าสู่ระบบด้วยรหัสผ่าน
                </Title>
                <Text>"สนุกกับการออกกำลังกายและพักผ่อนหย่อนใจ"</Text>
              </Col>
            </Row>

            <Form name="login" initialValues={{ remember: true }} onFinish={onFinish} layout="vertical">
              <Form.Item
                label="ชื่อผู้ใช้/Username"
                name="email"
                rules={[{ required: true, message: "Please input your Username!" }]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label="รหัสผ่าน/Password"
                name="password"
                rules={[{ required: true, message: "Please input your Password!" }]}
              >
                <Input.Password size="large" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  style={{ background: "#001978", borderColor: "#001978", margin: "20px 0px 20px 0px" }}
                >
                  เข้าสู่ระบบ
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginAdminForm;
