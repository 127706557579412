import React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Spin } from "antd";
import { useAuth } from "../context/auth-context";
import LoginPage from "../pages/login-page";
import Loading from "../components/loading-spin";

const UserRoutes = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Save the original path if the user is not logged in
  React.useEffect(() => {
    if (!user && location.pathname) {
      localStorage.setItem("originalPath", location.pathname);
    }
  }, [user, location.pathname]);

  // Redirect to original path after login
  React.useEffect(() => {
    const originalPath = localStorage.getItem("originalPath");
    if (user && originalPath) {
      navigate(originalPath);
      localStorage.removeItem("originalPath"); // Clear after redirect
    }
  }, [user, navigate]);

  // Show loading spinner while checking authentication status
  if (loading) {
    return <Loading />;
  }

  // If not authenticated, redirect to LoginPage
  if (!user) {
    return <LoginPage />;
  }

  // If authenticated, render the children routes
  return <Outlet />;
};

export default UserRoutes;
