import React from "react";
import { Row, Grid } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import RunningEventList from "../../../../components/sport-register/running-event-list";
import AppLayout from "../../../../layout/app-layout";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../../../components/menu-button";
import CategoryButton from "../../../../components/sport-register/category-button";
import FormControl from "../../../../components/sport-register/form-control";
import { fetchRunEvents } from "../../../../services/fetch-events";
import { useAuth } from "../../../../context/auth-context";
import { useAppContext } from "../../../../context/app-provider";
import { useTranslation } from "react-i18next";

const { useBreakpoint } = Grid;

const SportRegisterRunPage = ({}) => {
  const { userAdmin } = useAuth();
  const { language } = useAppContext();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = React.useState("run");
  const [loading, setLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedProvince, setSelectedProvince] = React.useState("");
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [events, setEvents] = React.useState([]);
  const [eventClose, setEventClose] = React.useState(null);
  const [eventOpen, setEventOpen] = React.useState(null);

  React.useEffect(() => {
    const loadEvents = async () => {
      try {
        setLoading(true);
        const { closeEventData, openEventData } = await fetchRunEvents("run", language); // เรียก API พร้อมพารามิเตอร์
        setEventClose(closeEventData);
        setEventOpen(openEventData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, [language]);

  const handleButtonClick = (type) => {
    setActiveButton(type);
    navigate(`/${language}/sport-register/${type}`);
  };

  const filteredOpenEvents = eventOpen?.filter((event) => {
    const eventMonth = event?.date?.split("/")[1];
    return (
      event?.Title?.toLowerCase().includes(searchTerm?.toLowerCase()) &&
      (selectedProvince === "" || event.location === selectedProvince) &&
      (selectedMonth === "" || eventMonth === selectedMonth)
    );
  });

  const filteredCloseEvents = eventClose?.filter((event) => {
    const eventMonth = event?.date?.split("/")[1];
    return (
      event?.Title?.toLowerCase().includes(searchTerm?.toLowerCase()) &&
      (selectedProvince === "" || event.location === selectedProvince) &&
      (selectedMonth === "" || eventMonth === selectedMonth)
    );
  });

  return (
    <AppLayout
      currentPage={`${t("app-header.title-1")} > ${t("app-header.title-2")}`}
      GetIconNav={<HomeOutlined style={{ fontSize: "24px" }} />}
      screens={screens}
    >
      {/* <MenuButton screens={screens} navigate={navigate} isSportRegisterPage={true} /> */}
      <div style={{ backgroundColor: "#5E72EB", padding: "24px" }}>
        <Row gutter={[16, 16]} align="middle">
          <CategoryButton activeButton={activeButton} handleButtonClick={handleButtonClick} />
          <FormControl
            t={t}
            screens={screens}
            setSearchTerm={setSearchTerm}
            setSelectedProvince={setSelectedProvince}
            setSelectedMonth={setSelectedMonth}
          />
        </Row>
      </div>
      <RunningEventList
        activeButton={activeButton}
        loading={loading}
        setSearchTerm={setSearchTerm}
        setSelectedProvince={setSelectedProvince}
        setSelectedMonth={setSelectedMonth}
        userAdmin={userAdmin}
        screens={screens}
        eventOpen={filteredOpenEvents}
        eventClose={filteredCloseEvents}
      />
    </AppLayout>
  );
};

export default SportRegisterRunPage;
