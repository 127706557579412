import React from "react";
import { Card, Table, Input, Button, Space, Col, Row, Flex, Badge, Modal, Form, Grid } from "antd";
import { SearchOutlined, ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { HomeOutlined } from "@ant-design/icons";
import AppLayoutAdmin from "../../layout/app-layout-admin";
import AdminLabel from "../../components/admin/admin-label";
import MenuButton from "../../components/menu-button";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;
const { useBreakpoint } = Grid;

const ManageMemberPage = ({ user }) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const [filteredInfo, setFilteredInfo] = React.useState({});
  const [pagination, setPagination] = React.useState({ current: 1, pageSize: 5 }); // ตั้งค่าหน้าและขนาดของแต่ละหน้า
  const searchInput = React.useRef(null);

  const [dataSource, setDataSource] = React.useState([
    {
      key: "1",
      number: 1,
      name: "เจตน์ษฎา ขวัญทอง",
      email: "j.a.y.jsd3@gmail.com",
      // raceOption: "FUNRUN",
      // ageGroup: "รุ่นอายุไม่เกิน 18 ปี",
      // paymentStatus: "Paid",
    },
    {
      key: "2",
      number: 2,
      name: "เจเจ",
      email: "j-a-y.jsd@hotmail.com",
      // raceOption: "HALF MARATHON",
      // ageGroup: "รุ่นอายุ 20-29 ปี",
      // paymentStatus: "Pending",
    },
    {
      key: "3",
      number: 3,
      name: "Chetsada Khwanthong",
      email: "Jaylnw00007@hotmail.com",
      // raceOption: "VIP",
      // ageGroup: "รุ่นอายุ 30-39 ปี",
      // paymentStatus: "Paid",
    },
    {
      key: "4",
      number: 4,
      name: "JsD",
      email: "j.a.y.jsd3@hotmail.com  ",
      // raceOption: "MINI MARATHON",
      // ageGroup: "รุ่นอายุ 40-49 ปี",
      // paymentStatus: "Pending",
    },
    {
      key: "5",
      number: 5,
      name: "CsD",
      email: "j.a.y.csd@gmail.com",
      // raceOption: "MINI MARATHON",
      // ageGroup: "รุ่นอายุ 40-49 ปี",
      // paymentStatus: "Paid",
    },
    {
      key: "6",
      number: 6,
      name: "CsD",
      email: "c.s.d@jsd@gmail.com",
      // raceOption: "VIP",
      // ageGroup: "รุ่นอายุ 50 ปีขึ้นไป",
      // paymentStatus: "Pending",
    },
  ]);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [editingRecord, setEditingRecord] = React.useState(null);

  const [form] = Form.useForm();

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const resetFilters = () => {
    setFilteredInfo({});
  };

  const handleTableChange = (newPagination, filters) => {
    setPagination(newPagination);
    setFilteredInfo(filters);
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure you want to delete this member?",
      icon: <ExclamationCircleOutlined />,
      content: `Member: ${record.name}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record.key);
      },
    });
  };

  const handleDelete = (key) => {
    const newDataSource = dataSource.filter((item) => item.key !== key);
    setDataSource(newDataSource);
  };

  const handleEdit = (record) => {
    setIsModalVisible(true);
    setEditingRecord(record);
    form.setFieldsValue({ name: record.name, email: record.email });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleModalSubmit = () => {
    form.validateFields().then((values) => {
      const newData = dataSource.map((item) => {
        if (item.key === editingRecord.key) {
          return { ...item, ...values };
        }
        return item;
      });
      setDataSource(newData);
      setIsModalVisible(false);
    });
  };

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "ชื่อ-สกุล",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name"),
      filteredValue: filteredInfo.name || null,
    },
    {
      title: "อีเมล์",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      filteredValue: filteredInfo.email || null,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <AppLayoutAdmin
      currentPage="หน้าแรก"
      getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />}
      user={user}
      screens={screens}
    >
      {/* Menu Button */}
      <AdminLabel screens={screens} />
      <MenuButton screens={screens} navigate={navigate} />
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card
            title="จัดการข้อมูลสมาชิก (Manage Member)"
            style={{
              borderRadius: 0,
              borderTop: "15px solid ",
              borderColor: "#5E72EB",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
            }}
          >
            <Flex justify="space-between" style={{ marginBottom: 16 }}>
              <Button onClick={() => navigate("/home2")}>ย้อนกลับ</Button>
              <Button onClick={resetFilters}>ล้างการฟิลเตอร์</Button>
            </Flex>
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={pagination}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
            />
          </Card>

          <Modal title="Edit Member" open={isModalVisible} onCancel={handleModalCancel} onOk={handleModalSubmit}>
            <Form form={form} layout="vertical" name="edit_member">
              <Form.Item name="name" label="ชื่อ-สกุล" rules={[{ required: true, message: "กรุณากรอกชื่อ" }]}>
                <Input />
              </Form.Item>
              <Form.Item name="email" label="อีเมล์" rules={[{ required: true, message: "กรุณากรอกอีเมล์" }]}>
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </Col>
      </Row>
    </AppLayoutAdmin>
  );
};
export default ManageMemberPage;
