import React from "react";
import { Card, Row, Col, Typography, Image, Divider, Space, Button } from "antd";

const { Title, Text } = Typography;

const CircleDecoration = ({ top, left }) => (
  <div
    style={{
      position: "absolute",
      top,
      left,
      transform: "translate(-50%, -50%)",
      width: 40,
      height: 40,
      backgroundColor: "white",
      borderRadius: "50%",
      zIndex: 1,
    }}
  />
);

const getTitle = (
  isMobileScreen,
  isSmallScreen,
  isMediumScreen,
  isLargeScreen,
  isExtraLargeScreen,
  isExtraExtraLargeScreen
) => {
  if (isMobileScreen || (isSmallScreen && !isMediumScreen))
    return (
      <Typography.Paragraph strong level={1} style={{ margin: 0 }}>
        วิ่งลอยฟ้า
      </Typography.Paragraph>
    );
  if (isSmallScreen && isMediumScreen)
    return (
      <Title level={3} style={{ margin: 0 }}>
        วิ่งลอยฟ้า
      </Title>
    );
  if (isSmallScreen && isMediumScreen && isLargeScreen)
    return (
      <Title level={1} style={{ margin: 0 }}>
        วิ่งลอยฟ้า
      </Title>
    );
  if (isSmallScreen && isMediumScreen && isLargeScreen && isExtraLargeScreen)
    return (
      <Title level={1} style={{ margin: 0 }}>
        วิ่งลอยฟ้า
      </Title>
    );
  if (isSmallScreen && isMediumScreen && isLargeScreen && isExtraLargeScreen && isExtraExtraLargeScreen)
    return (
      <Title level={1} style={{ margin: 0 }}>
        วิ่งลอยฟ้า
      </Title>
    );
  return null;
};

const getDescriptionSelf = (
  isMobileScreen,
  isSmallScreen,
  isMediumScreen,
  isLargeScreen,
  isExtraLargeScreen,
  isExtraExtraLargeScreen
) => {
  return isMobileScreen || (isSmallScreen && !isMediumScreen) ? (
    <Typography.Paragraph strong style={{ paddingTop: 10, margin: 0 }}>
      Chetsada Khwanthong
    </Typography.Paragraph>
  ) : (
    <Typography.Paragraph strong style={{ paddingTop: 10, margin: 0 }}>
      ชื่อ : Chetsada Khwanthong
    </Typography.Paragraph>
  );
};

const getDescriptionFriend = (
  isMobileScreen,
  isSmallScreen,
  isMediumScreen,
  isLargeScreen,
  isExtraLargeScreen,
  isExtraExtraLargeScreen
) => {
  return isMobileScreen || (isSmallScreen && !isMediumScreen) ? (
    <Typography.Paragraph strong style={{ paddingTop: 10, margin: 0 }}>
      Wuttichon
    </Typography.Paragraph>
  ) : (
    <Typography.Paragraph strong style={{ paddingTop: 10, margin: 0 }}>
      ชื่อ : Wuttichon
    </Typography.Paragraph>
  );
};

const renderCircles = (
  isMobileScreen,
  isLargeScreen,
  isSmallScreen,
  isMediumScreen,
  isExtraLargeScreen,
  isExtraExtraLargeScreen
) => {
  if (isMobileScreen && !isLargeScreen) {
    return (
      <>
        <CircleDecoration top="55%" left="7%" />
        <CircleDecoration top="55%" left="93%" />
      </>
    );
  } else if (isSmallScreen) {
    return (
      <>
        <CircleDecoration top="10%" left="42%" />
        <CircleDecoration top="90%" left="42%" />
      </>
    );
  }
  return null;
};

const Ticket = ({
  selectedTicket,
  setSelectedTicket,
  isMobileScreen,
  isSmallScreen,
  isMediumScreen,
  isLargeScreen,
  isExtraLargeScreen,
  isExtraExtraLargeScreen,
}) => {
  const isSelf = selectedTicket === "self";

  return (
    <>
      <Row justify="center" align="middle">
        <Col span={12}>
          <Button
            type="link"
            block
            onClick={() => setSelectedTicket("self")}
            style={{
              backgroundColor: isSelf ? "#5E72EB" : "#FFFFFF",
              color: isSelf ? "#FFFFFF" : "#5E72EB",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            My Cart
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="link"
            block
            onClick={() => setSelectedTicket("friend")}
            style={{
              backgroundColor: !isSelf ? "#5E72EB" : "#FFFFFF",
              color: !isSelf ? "#FFFFFF" : "#5E72EB",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            Friend's Cart
          </Button>
        </Col>
      </Row>

      {isSelf && (
        <Card bordered={false} style={{ width: "100%", marginBottom: "20px" }}>
          <Row>
            <Col
              xs={24}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              style={{
                backgroundColor: "#f0f2f5",
                padding: 20,
                borderRadius: isMobileScreen ? "10px 10px 0px 0px" : "10px 0px 0px 10px",
                position: "relative",
              }}
            >
              {getTitle(
                isMobileScreen,
                isSmallScreen,
                isMediumScreen,
                isLargeScreen,
                isExtraLargeScreen,
                isExtraExtraLargeScreen
              )}
              <Text>จังหวัด สงขลา</Text>
              <Divider style={{ margin: 8 }} />
              <Row justify="space-around" align="middle">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Image src="/qr-code.svg" />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Space direction="vertical" size={0}>
                    <Typography.Paragraph strong style={{ margin: 0 }}>
                      รหัส.0001
                    </Typography.Paragraph>
                    <Text>Race: 5 Km.</Text>
                    <Text>Age: Overall</Text>
                  </Space>
                </Col>
              </Row>
              {getDescriptionSelf(
                isMobileScreen,
                isSmallScreen,
                isMediumScreen,
                isLargeScreen,
                isExtraLargeScreen,
                isExtraExtraLargeScreen
              )}
            </Col>

            <Col xs={24} sm={14} md={14} lg={14} xl={14} xxl={14} style={{ height: "100%" }}>
              <Image
                preview={false}
                src="/ticket-image.svg"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: isMobileScreen ? "0px 0px 10px 10px" : "0px 10px 10px 0px",
                }}
              />
            </Col>
            {renderCircles(
              isMobileScreen,
              isLargeScreen,
              isSmallScreen,
              isMediumScreen,
              isExtraLargeScreen,
              isExtraExtraLargeScreen
            )}
          </Row>
        </Card>
      )}

      {!isSelf && (
        <Card bordered={false} style={{ width: "100%", marginBottom: "20px" }}>
          <Row>
            <Col
              span={10}
              style={{
                backgroundColor: "#f0f2f5",
                padding: 20,
                borderRadius: "10px 0px 0px 10px",
                position: "relative",
              }}
            >
              {getTitle(
                isMobileScreen,
                isSmallScreen,
                isMediumScreen,
                isLargeScreen,
                isExtraLargeScreen,
                isExtraExtraLargeScreen
              )}
              <Text>จังหวัด สงขลา</Text>
              <Divider style={{ margin: 8 }} />
              <Row justify="space-around" align="middle">
                <Col span={12}>
                  <Image src="./qr-code.svg" />
                </Col>
                <Col span={12}>
                  <Space direction="vertical" size={0}>
                    <Typography.Paragraph strong style={{ margin: 0 }}>
                      รหัส.0001
                    </Typography.Paragraph>
                    <Text>Race: 5 Km.</Text>
                    <Text>Age: Overall</Text>
                  </Space>
                </Col>
              </Row>
              {getDescriptionFriend(
                isMobileScreen,
                isSmallScreen,
                isMediumScreen,
                isLargeScreen,
                isExtraLargeScreen,
                isExtraExtraLargeScreen
              )}
            </Col>

            <Col span={14}>
              <Image
                preview={false}
                src="./ticket-image.svg"
                style={{ width: "100%", height: "100%", borderRadius: "0px 10px 10px 0px" }}
              />
            </Col>
            {renderCircles(
              isMobileScreen,
              isLargeScreen,
              isSmallScreen,
              isMediumScreen,
              isExtraLargeScreen,
              isExtraExtraLargeScreen
            )}
          </Row>
        </Card>
      )}
    </>
  );
};

export default Ticket;
