import React from "react";
import { Col, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

const FormControl = ({ t, setSearchTerm, setSelectedProvince, setSelectedMonth, screens }) => {
  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleProvinceChange = (value) => setSelectedProvince(value);
  const handleMonthChange = (value) => setSelectedMonth(value);
  const provinces = ["", ...Array.from({ length: 77 }, (_, index) => t(`provinces.${index + 1}`))];

  const months = [
    { value: "", label: "" },
    { value: "01", label: t("search-field.month-1") },
    { value: "02", label: t("search-field.month-2") },
    { value: "03", label: t("search-field.month-3") },
    { value: "04", label: t("search-field.month-4") },
    { value: "05", label: t("search-field.month-5") },
    { value: "06", label: t("search-field.month-6") },
    { value: "07", label: t("search-field.month-7") },
    { value: "08", label: t("search-field.month-8") },
    { value: "09", label: t("search-field.month-9") },
    { value: "10", label: t("search-field.month-10") },
    { value: "11", label: t("search-field.month-11") },
    { value: "12", label: t("search-field.month-12") },
  ];
  return (
    <>
      <Col xs={24} sm={12} md={14} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }}>
        <Input
          size={screens?.xs ? "middle" : "large"}
          placeholder={t("sport-register-page.search-input")}
          prefix={<SearchOutlined />}
          style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)" }}
          onChange={handleSearchChange}
        />
      </Col>
      <Col xs={12} sm={6} md={5} lg={3} xl={{ span: 3, offset: 1 }}>
        <Select
          onChange={handleProvinceChange}
          className="title-select"
          size={screens?.xs ? "middle" : "large"}
          placeholder={t("sport-register-page.select-city")}
          style={{ width: "100%", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)" }}
        >
          {provinces.map((province) => (
            <Option key={province} value={province}>
              {province}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={12} sm={6} md={5} lg={3} xl={{ span: 3, offset: 1 }}>
        <Select
          onChange={handleMonthChange}
          className="title-select"
          size={screens?.xs ? "middle" : "large"}
          placeholder={t("sport-register-page.select-month")}
          style={{ width: "100%", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)" }}
        >
          {months.map((month) => (
            <Option key={month.value} value={month.value}>
              {month.label}
            </Option>
          ))}
        </Select>
      </Col>
    </>
  );
};

export default FormControl;
