import React from "react";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import LoginPage from "../pages/login-page";
import RegisterPage from "../pages/register-page";
import Home from "../pages/home-page";
import Profile from "../pages/profile-page";
import LoginAdminPage from "../pages/login-admin-page";
import HomeAdmin from "../pages/home-admin-page";
import RegisterRunFormPage from "../pages/home/sport-register/run/register-run-form-page";
import DetailEventRunPage from "../pages/home/sport-register/run/detail-event-run-page";
import MemberCheckRunPage from "../pages/home/sport-register/run/member-check-run-page";
import SportRegisterRunPage from "../pages/home/sport-register/run/sport-register-run-page";
import SportRegisterBikePage from "../pages/home/sport-register/bike/sport-register-bike-page";
import SportRegisterSportPage from "../pages/home/sport-register/sport/sport-register-sport-page";
import ManageMemberPage from "../pages/admin/manage-member-page";
import ManageCheckAndUpdatePage from "../pages/admin/sport-register/run/manage-check-and-update-run-page";
import HotelPage from "../pages/home/hotel/hotel-page";
import CarRentPage from "../pages/home/car-rent/car-rent-page";
import ShuttlePage from "../pages/home/shuttle/shuttle-page";
import BookATourPage from "../pages/home/tour/tour-page";
import OneDayTripPage from "../pages/home/trip/trip-page";
import EvChargerPage from "../pages/home/Ev-charger/ev-charger-page";
import ThaiShopPage from "../pages/home/thai-shop/thai-shop-page";
import AnotherPage from "../pages/home/another/another-page";
import UserRoutes from "./user-routes";
import AdminRoutes from "./admin-routes";
import AuthRoute from "./auth-routes";
import AdminEventDetailPage from "../pages/admin/sport-register/run/detail-event-run-admin-page";
import SportRegisterRunAdminPage from "../pages/admin/sport-register/run/sport-register-run-admin-page";
import SportRegisterBikeAdminPage from "../pages/admin/sport-register/bike/sport-register-bike-admin-page";
import SportRegisterSportAdminPage from "../pages/admin/sport-register/sport/sport-register-sport-admin-page";
import HotelAdminPage from "../pages/admin/hotel/hotel-page";
import CarRentAdminPage from "../pages/admin/car-rent/car-rent-page";
import ShuttleAdminPage from "../pages/admin/shuttle/shuttle-page";
import BookATourAdminPage from "../pages/admin/tour/tour-page";
import OneDayTripAdminPage from "../pages/admin/trip/trip-page";
import EvChargerAdminPage from "../pages/admin/Ev-charger/ev-charger-page";
import ThaiShopAdminPage from "../pages/admin/thai-shop/thai-shop-page";
import AnotherAdminPage from "../pages/admin/another/another-page";
import EventDetail from "../pages/home/my-register-event-page";
import OrderShirtPage from "../pages/home/my-order-shirt-page";
import AddEvent from "../pages/admin/sport-register/run/add-run-event";
import DetailEventBikePage from "../pages/home/sport-register/bike/detail-event-bike-page";
import MemberCheckBikePage from "../pages/home/sport-register/bike/member-check-bike-page";
import RegisterBikeFormPage from "../pages/home/sport-register/bike/register-bike-fom-page";
import ManageCheckAndUpdateOrderShirtRunPage from "../pages/admin/sport-register/run/manage-check-and-update-order-shirt-run-page";
import ManageCheckAndUpdateBikePage from "../pages/admin/sport-register/bike/manage-check-and-update-bike-page";
import AdminEventDetailBikePage from "../pages/admin/sport-register/bike/detail-event-bike-admin-page";

const Routes = () => {
  const element = useRoutes([
    {
      path: "/",
      element: <Navigate to="/th" replace />, // Redirect จาก root ไป "/th"
    },
    {
      path: "/:lang", // เพิ่มระดับของ path สำหรับภาษา
      children: [
        { path: "", element: <Home /> },
        {
          element: <AuthRoute />,
          children: [
            { path: "login", element: <LoginPage /> },
            { path: "register", element: <RegisterPage /> },
            { path: "admin", element: <LoginAdminPage /> },
          ],
        },
        {
          element: <UserRoutes />,
          children: [
            { path: "", element: <Home /> },
            { path: "profile", element: <Profile /> },
            { path: "profile/my-event", element: <EventDetail /> },
            { path: "profile/my-order", element: <OrderShirtPage /> },
            { path: "sport-register/run/register/:id", element: <RegisterRunFormPage /> },
            { path: "sport-register/bicycle/register/:id", element: <RegisterBikeFormPage /> },
            { path: "sport-register/run/member/:id", element: <MemberCheckRunPage /> },
            { path: "sport-register/bicycle/member/:id", element: <MemberCheckBikePage /> },
          ],
        },
        { path: "sport-register/run", element: <SportRegisterRunPage /> },
        { path: "sport-register/bicycle", element: <SportRegisterBikePage /> },
        { path: "sport-register/sport", element: <SportRegisterSportPage /> },
        { path: "hotel", element: <HotelPage /> },
        { path: "car-rent", element: <CarRentPage /> },
        { path: "shuttle", element: <ShuttlePage /> },
        { path: "tour", element: <BookATourPage /> },
        { path: "trip", element: <OneDayTripPage /> },
        { path: "ev-charger", element: <EvChargerPage /> },
        { path: "thai-shop", element: <ThaiShopPage /> },
        { path: "another", element: <AnotherPage /> },
        { path: "sport-register/run/detail/:id", element: <DetailEventRunPage /> },
        { path: "sport-register/bicycle/detail/:id", element: <DetailEventBikePage /> },
      ],
    },
    {
      path: "/:lang", // แยก route สำหรับ admin โดยใช้ path เดียวกันเพื่อการจัดการในระดับภาษาทั้งหมด
      element: <AdminRoutes />,
      children: [
        { path: "", element: <HomeAdmin /> },
        { path: "event/run", element: <SportRegisterRunAdminPage /> },
        { path: "event/bicycle", element: <SportRegisterBikeAdminPage /> },
        { path: "event/sport", element: <SportRegisterSportAdminPage /> },
        { path: "event/run/add-event", element: <AddEvent /> },
        { path: "event/run/manage-check-update/member/:id", element: <ManageCheckAndUpdatePage /> },
        { path: "event/run/manage-check-update/order-shirt/:id", element: <ManageCheckAndUpdateOrderShirtRunPage /> },
        { path: "event/run/detail/:id", element: <AdminEventDetailPage /> },
        { path: "event/bicycle/manage-check-update/member/:id", element: <ManageCheckAndUpdateBikePage /> },
        {
          path: "event/bicycle/manage-check-update/order-shirt/:id",
          element: <ManageCheckAndUpdateOrderShirtRunPage />,
        },
        { path: "event/bicycle/detail/:id", element: <AdminEventDetailBikePage /> },
        { path: "hotel", element: <HotelAdminPage /> },
        { path: "car-rent", element: <CarRentAdminPage /> },
        { path: "shuttle", element: <ShuttleAdminPage /> },
        { path: "tour", element: <BookATourAdminPage /> },
        { path: "trip", element: <OneDayTripAdminPage /> },
        { path: "ev-charger", element: <EvChargerAdminPage /> },
        { path: "thai-shop", element: <ThaiShopAdminPage /> },
        { path: "another", element: <AnotherAdminPage /> },
      ],
    },
    // { path: "*", element: <NotFoundPage /> },
  ]);

  return element;
};

export default Routes;
