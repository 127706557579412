import React from "react";
import { Row, Layout, Grid, Image, Col } from "antd";
import LoginAdminForm from "../components/admin/login-form-admin";
import AppLayoutLogin from "../layout/app-layout-login";
import { useAppContext } from "../context/app-provider";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const LoginAdminPage = () => {
  const screens = useBreakpoint();
  const { language } = useAppContext();

  return (
    <AppLayoutLogin screens={screens}>
      <Row
        justify="center"
        align="middle"
        style={{
          backgroundImage: `url("/main-banner-admin.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          width: "100%",
          borderRadius: screens.xs ? 0 : 20,
        }}
      >
        <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12} style={{ alignSelf: "start" }}>
          <Image
            src="/paipao-with-thai-word.svg"
            preview={false}
            style={{
              width: "300px",
              height: "auto",
            }}
          />
        </Col>
        <Col xs={22} sm={22} md={20} lg={12} xl={12} xxl={12}>
          <LoginAdminForm language={language} />
        </Col>
      </Row>
    </AppLayoutLogin>
  );
};

export default LoginAdminPage;
