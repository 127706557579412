import React from "react";
import { Layout, Menu } from "antd";
import {
  UserOutlined,
  VideoCameraOutlined,
  DollarOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { auth } from "../components/firebase";

const { Sider, Content } = Layout;

const items = [
  {
    key: "activities",
    icon: React.createElement(UserOutlined),
    label: "กิจกรรม",
    children: [
      { key: "allActivities", label: "กิจกรรมทั้งหมด" },
      { key: "addActivity", label: "เพิ่ม กิจกรรม" },
      { key: "suspendedActivities", label: "กิจกรรม ที่ถูกระงับ" },
    ],
  },
  {
    key: "videos",
    icon: React.createElement(VideoCameraOutlined),
    label: "วิดีโอ",
    children: [
      { key: "myVideos", label: "วิดีโอของฉัน" },
      { key: "addVideo", label: "เพิ่มวิดีโอใหม่" },
    ],
  },
  {
    key: "marketing",
    icon: React.createElement(DollarOutlined),
    label: "Marketing Center",
    children: [
      { key: "ads", label: "Paipao ADS" },
      { key: "discounts", label: "ส่วนลด" },
    ],
  },
  {
    key: "finance",
    icon: React.createElement(DollarOutlined),
    label: "การเงิน",
  },
  {
    key: "account",
    icon: React.createElement(UserOutlined),
    label: "บัญชีของฉัน",
    children: [
      { key: "accountSettings", label: "ตั้งค่าบัญชี" },
      { key: "chatSettings", label: "ตั้งค่าระบบแชท" },
    ],
  },
  {
    key: "help",
    icon: React.createElement(QuestionCircleOutlined),
    label: "ช่วยเหลือ",
  },
  {
    key: "logout",
    icon: React.createElement(LogoutOutlined),
    label: "ออกจากระบบ",
  },
];

const AppLayoutSider = ({ screens, children }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();

      navigate("/admin");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

  const handleMenuClick = (e) => {
    if (e.key === "logout") {
      handleLogout();
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={300}
        style={{ background: "#fff" }}
        collapsible
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        breakpoint="lg"
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["activities", "videos", "marketing", "account"]}
          style={{ height: "100%", borderRight: 0 }}
          items={items}
          onClick={handleMenuClick}
        />
      </Sider>
      <Content>{children}</Content>
    </Layout>
  );
};

export default AppLayoutSider;
