import React from "react";
import { Row, Grid, Image, Col } from "antd";
import LoginForm from "../components/login-form";
import AppLayoutLogin from "../layout/app-layout-login";

const { useBreakpoint } = Grid;

const LoginPage = () => {
  const screens = useBreakpoint();

  return (
    <AppLayoutLogin screens={screens}>
      <Row
        justify="center"
        align="middle"
        style={{
          backgroundImage: `url("/main-banner.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          width: "100%",
          borderRadius: 20,
        }}
      >
        <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12} style={{ alignSelf: "start" }}>
          <Image
            src="/paipao-with-thai-word.svg"
            preview={false}
            style={{
              width: "300px",
              height: "auto",
            }}
          />
        </Col>
        <Col xs={22} sm={22} md={20} lg={12} xl={12} xxl={12}>
          <LoginForm />
        </Col>
      </Row>
    </AppLayoutLogin>
  );
};

export default LoginPage;
