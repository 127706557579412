import { createUserWithEmailAndPassword } from "firebase/auth";
import React from "react";
import { Form, Input, Button, Row, Col, Typography, Space, Card, Divider, Image } from "antd";
import { auth, db } from "./firebase";
import { toast } from "react-toastify";
import { createUser } from "../services/fetch-events";
import { useAppContext } from "../context/app-provider";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const RegisterForm = ({ navigate }) => {
  const [loading, setLoading] = React.useState(false);
  const { language } = useAppContext();
  const { t } = useTranslation();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await createUserWithEmailAndPassword(auth, values.email, values.password);
      const user = auth.currentUser;
      if (user) {
        const userData = {
          fullName: values?.fullName,
          email: user.email,
          password: values.password,
          call: values.call,
          photo: "",
          userID: user.uid,
          myEvents: {},
          providers: "email",
        };

        // Use the API function to add the user
        await createUser(userData);
      }
      navigate(`/${language}/login`);
      toast.success(t("register-page.title-3"), {
        position: "top-center",
      });
    } catch (error) {
      console.log(error.message);
      toast.error(error.message, {
        position: "bottom-center",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center" align="middle">
      <Col>
        <Card
          size="small"
          style={{
            maxWidth: "400px",
            padding: "40px 20px",
            borderRadius: "60px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            margin: "20px 0px",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <Row align="middle">
              <Col span={8}>
                <Image src="/paipao-logo.png" preview={false} width="80%" />
              </Col>
              <Col span={16}>
                <Title level={5} style={{ margin: 0 }}>
                  {t("register-page.title-1")}
                </Title>
                <Text>"{t("register-page.title-2")}"</Text>
              </Col>
            </Row>

            <Form name="register" initialValues={{ remember: true }} onFinish={onFinish} layout="vertical">
              <Form.Item
                label={t("register-page.title-fullName")}
                name="fullName"
                rules={[{ required: true, message: t("register-page.title-4") }]}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item
                label={t("register-page.title-email")}
                name="email"
                rules={[
                  { required: true, message: t("register-page.title-5") },
                  { type: "email", message: t("register-page.title-6") },
                ]}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item
                label={t("register-page.title-password")}
                name="password"
                rules={[{ required: true, message: t("register-page.title-7") }]}
              >
                <Input.Password size="large" />
              </Form.Item>

              <Form.Item
                label={t("register-page.title-phone")}
                name="call"
                rules={[{ required: true, message: t("register-page.title-8") }]}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={loading}
                  style={{ background: "#001978", borderColor: "#001978", margin: "20px 0px" }}
                >
                  {t("register-page.register-button")}
                </Button>
              </Form.Item>
              <Divider style={{ height: "2px", backgroundColor: "#d9d9d9", margin: "20px 0" }} />
              <Col span={24}>
                <Button
                  size="large"
                  block
                  style={{ background: "#EFE26A", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)" }}
                  onClick={() => navigate(`/${language}/login`)}
                >
                  <Title level={5} style={{ margin: 0, color: "#FFFFFF" }}>
                    {t("register-page.title-alreadyHave")}
                  </Title>
                </Button>
              </Col>
            </Form>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default RegisterForm;
