import React from "react";
import { Col, Button, Space, Image, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const CategoryButton = ({ activeButton, handleButtonClick }) => {
  const { t } = useTranslation();
  const buttonData = [
    { type: "run", icon: "running-icon", label: t("sport-register-page.button-1") },
    { type: "bicycle", icon: "bicycle-icon", label: t("sport-register-page.button-2") },
    { type: "sport", icon: "medal-icon", label: t("sport-register-page.button-3") },
  ];

  return (
    <>
      {buttonData.map((item, index) => (
        <Col
          key={item.type}
          xs={8}
          sm={8}
          md={8}
          lg={4}
          xl={3}
          style={{
            backgroundColor: "#150E6E",
            padding: "8px",
            borderRadius: index === 0 ? "12px 0 0 12px" : index === 2 ? "0 12px 12px 0" : "0",
          }}
        >
          <Button
            style={{
              backgroundColor: activeButton === item?.type ? "#FFFFFF" : "transparent",
              color: activeButton === item?.type ? "#150E6E" : "#FFFFFF",
              width: "100%",
              height: "100%",
              borderRadius: "12px",
              border: "none",
              boxShadow: activeButton === item?.type ? "0px 4px 15px rgba(0, 0, 0, 0.15)" : "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => handleButtonClick(item?.type)}
          >
            <Space direction="vertical" align="center">
              <Image
                preview={false}
                src={activeButton === item.type ? `/${item?.icon}-purple.svg` : `/${item?.icon}-white.svg`}
                style={{ width: "50px", height: "50px" }}
              />
              <Title level={5} style={{ margin: 0, color: activeButton === item?.type ? "#150E6E" : "#FFFFFF" }}>
                {item?.label}
              </Title>
            </Space>
          </Button>
        </Col>
      ))}
    </>
  );
};

export default CategoryButton;
