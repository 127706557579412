import React from "react";
import { Button } from "antd";
import * as XLSX from "xlsx";

const DownloadExcel = ({ memberLists, events }) => {
  const hasTitle = events?.formDetail?.title;
  const hasAddress = events?.formDetail?.address;
  const hasBirthDate = events?.formDetail?.birthDate;
  const hasBloodGroup = events?.formDetail?.bloodGroup;
  const hasFirstName = events?.formDetail?.firstName;
  const hasIdPass = events?.formDetail?.idPass;
  const hasLastName = events?.formDetail?.lastName;
  const hasOtherContact = events?.formDetail?.otherContact;
  const hasOtherPhone = events?.formDetail?.otherPhone;
  const hasPhone = events?.formDetail?.phone;

  const handleDownloadExcel = () => {
    // กรองเฉพาะสมาชิกที่มีสถานะเป็น "paid"
    const filteredMembers = memberLists.filter((member) => member.status === "paid");

    // แปลงข้อมูลจาก filteredMembers
    const formattedData = filteredMembers.map((member) => {
      const data = {
        วันที่สมัคร: member.date,
        เวลาที่สมัคร: member.time,
        คำนำหน้า: member.title,
        ชื่อ: member.firstName,
        สกุล: member.lastName,
        ที่อยู่: member.address,
        ชื่องาน: member.raceOption,
        รุ่นอายุ: member.ageGroup,
        ขนาดเสื้อ: member.shirtSize,
        อีเมลผู้สมัคร: member.refAcc,
        เบอร์โทรศัพท์: member.phone,
      };

      if (hasIdPass) data["เลขบัตรประชาชนหรือเลขพาสปอร์ต"] = member.idPass;
      if (hasOtherContact) data["ช่องทางติดต่อออนไลน์"] = member.otherContact;
      if (hasBloodGroup) data["กรุ๊ปเลือด"] = member.bloodGroup;
      if (hasOtherPhone) data["เบอร์โทรศัพท์ญาติ"] = member.otherPhone;

      data["ชื่อทีม"] = member.groupName;
      data["จัดส่งเสื้อ"] = member.delivery ? "รับ" : "ไม่รับ";
      data["ประกันชีวิต"] = member.lifeInsurance ? "รับ" : "ไม่รับ";
      data["ค่าสมัคร"] = member.totalPrice;
      data["สถานะการจ่ายเงิน"] = member.status === "paid" ? "จ่ายเงินเรียบร้อย" : "";
      data["รูปภาพสลิป"] = member.qrImage;

      return data;
    });

    // สร้าง sheet จาก formattedData
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // สร้าง workbook และเพิ่ม worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Paid Members");

    // สร้างไฟล์ Excel และเริ่มดาวน์โหลด
    XLSX.writeFile(workbook, "รายชื่อผู้สมัคร.xlsx");
  };

  return (
    <Button type="primary" onClick={handleDownloadExcel}>
      ดาวน์โหลดข้อมูลสมาชิกที่จ่ายเงินเป็น Excel
    </Button>
  );
};

export default DownloadExcel;
