import React from "react";
import { Card, Table, Input, Button, Col, Row, Flex, Badge, Grid } from "antd";
import { SearchOutlined, HomeOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import ButtonMenuRow from "../../../../components/sport-register/button-menu-row";
import ImageEventList from "../../../../components/sport-register/image-event-list";
import MenuButton from "../../../../components/menu-button";
import CategoryButton from "../../../../components/sport-register/category-button";
import FormControl from "../../../../components/sport-register/form-control";
import { fetchEventById, fetchEventMembers, fetchRunEvents } from "../../../../services/fetch-events";
import RunningEventList from "../../../../components/sport-register/running-event-list";
import { useAuth } from "../../../../context/auth-context";
import AppLayout from "../../../../layout/app-layout";
import { useAppContext } from "../../../../context/app-provider";
import { useTranslation } from "react-i18next";

const { useBreakpoint } = Grid;

const MemberCheckRunPage = ({}) => {
  const { user } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const [loading, setLoading] = React.useState(false);
  const { language } = useAppContext();
  const { t } = useTranslation();
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const [filteredInfo, setFilteredInfo] = React.useState({});
  const [pagination, setPagination] = React.useState({ current: 1, pageSize: 10 }); // ตั้งค่าหน้าและขนาดของแต่ละหน้า
  const searchInput = React.useRef(null);
  const [events, setEvents] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [eventsSearch, setEventsSearch] = React.useState([]);
  const [activeButton, setActiveButton] = React.useState("run");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedProvince, setSelectedProvince] = React.useState("");
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [eventClose, setEventClose] = React.useState(null);
  const [eventOpen, setEventOpen] = React.useState(null);

  React.useEffect(() => {
    const getEventData = async () => {
      try {
        setLoading(true);
        const { eventData, members } = await fetchEventById(id, language);
        setEvents(eventData);
        setMembers(members);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getEventData();
  }, [id, language]);

  React.useEffect(() => {
    const loadEvents = async () => {
      try {
        setLoading(true);
        const { closeEventData, openEventData } = await fetchRunEvents("run", language); // เรียก API พร้อมพารามิเตอร์
        setEventClose(closeEventData);
        setEventOpen(openEventData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, []);

  const filteredOpenEvents = eventOpen?.filter((event) => {
    const eventMonth = event?.date?.split("/")[1];
    return (
      event?.Title?.toLowerCase().includes(searchTerm?.toLowerCase()) &&
      (selectedProvince === "" || event.location === selectedProvince) &&
      (selectedMonth === "" || eventMonth === selectedMonth)
    );
  });

  const filteredCloseEvents = eventClose?.filter((event) => {
    const eventMonth = event?.date?.split("/")[1];
    return (
      event?.Title?.toLowerCase().includes(searchTerm?.toLowerCase()) &&
      (selectedProvince === "" || event.location === selectedProvince) &&
      (selectedMonth === "" || eventMonth === selectedMonth)
    );
  });

  const groupNames = Array.from(new Set(members.map((event) => event.groupName).filter((name) => name))).map(
    (name) => ({
      text: name,
      value: name,
    })
  );

  const handleButtonClick = (type) => {
    setActiveButton(type);
    navigate(`/${language}/sport-register/${type}`);
  };

  const dataSource = members;

  const getDisplaySize = (text) => {
    let displaySize = text; // ค่าที่จะแสดงเป็นค่าเริ่มต้น

    // แปลงค่า shirtSize ตามที่กำหนด
    switch (text) {
      case "XXL":
        displaySize = "2XL";
        break;
      case "XXXL":
        displaySize = "3XL";
        break;
      case "XXXXL":
        displaySize = "4XL";
        break;
      case "XXXXXL":
        displaySize = "5XL";
        break;
      case "XXS":
        displaySize = "2XS";
        break;
      default:
        break;
    }
    return displaySize; // คืนค่าที่แปลงแล้ว
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      const fullName = `${record.firstName} ${record.lastName}`.toLowerCase();
      return fullName.includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const resetFilters = () => {
    setFilteredInfo({});
  };

  const handleTableChange = (newPagination, filters) => {
    setPagination(newPagination);
    setFilteredInfo(filters);
  };

  const columns = [
    {
      title: t("check-member-page.title-1"),
      dataIndex: "number",
      key: "number",
      render: (_, __, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
      width: 100,
    },
    {
      title: t("check-member-page.title-2"),
      dataIndex: "title",
      key: "title",
      width: 120,
      filters: [
        { text: "นาย", value: "นาย" },
        { text: "นาง", value: "นาง" },
        { text: "นางสาว", value: "นางสาว" },
      ],
      onFilter: (value, record) => record.title === value,
      filteredValue: filteredInfo.title || null,
    },
    {
      title: t("check-member-page.title-3"),
      dataIndex: "firstName",
      key: "fullName",
      sorter: (a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
      render: (text, record) => `${record.firstName} ${record.lastName}`, // รวมชื่อและนามสกุล
      ...getColumnSearchProps("fullName"),
      filteredValue: filteredInfo.fullName || null,
    },
    {
      title: t("check-member-page.title-4"),
      dataIndex: "shirtSize",
      key: "shirtSize",
      filters: Array.from(
        new Set(
          Object.keys(events?.registerDetail || {}).flatMap(
            (eventKey) =>
              Object.entries(events.registerDetail[eventKey].shirtSize || {})
                // จัดเรียงรอบอกตามตัวเลข
                .sort(([, aChest], [, bChest]) => {
                  const chestA = parseInt(aChest.split(" ")[1], 10); // ดึงตัวเลขจาก "รอบอก 44"
                  const chestB = parseInt(bChest.split(" ")[1], 10);
                  return chestA - chestB;
                })
                .map(([size]) => size) // ดึงเฉพาะค่า size ออกมา
          )
        )
      ).map((size) => ({
        text: size,
        value: size,
      })),
      onFilter: (value, record) => record.shirtSize === value,
      filteredValue: filteredInfo.shirtSize || null,
      render: (text) => text,
    },
    {
      title: t("check-member-page.title-5"),
      dataIndex: "raceOption",
      key: "raceOption",
      filters:
        Object.keys(events?.registerDetail || {}).map((key) => ({
          text: events.registerDetail[key].eventName, // แสดงชื่อรุ่นจาก eventName
          value: key, // ใช้ key เป็นค่าที่จะกรอง
        })) || [],
      onFilter: (value, record) => record.raceOption === value,
      filteredValue: filteredInfo.raceOption || null,
    },
    {
      title: t("check-member-page.title-6"),
      dataIndex: "ageGroup",
      key: "ageGroup",
      filters:
        Object.values(events?.registerDetail || {})
          .flatMap((detail) => [...detail.typeDetailMale, ...detail.typeDetailFemale])
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((detail) => ({
            text: detail,
            value: detail,
          })) || [],
      onFilter: (value, record) => record.ageGroup === value,
      filteredValue: filteredInfo.ageGroup || null,
    },
    // {
    //   title: "ชื่อทีม",
    //   dataIndex: "groupName",
    //   key: "groupName",
    //   filters: groupNames,
    //   onFilter: (value, record) => record.groupName === value,
    //   filteredValue: filteredInfo.groupName || null,
    //   render: (groupName) => (groupName ? groupName : "-"),
    // },
    // {
    //   title: "สถานะการจ่ายเงิน",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (status) => (
    //     <Badge status={status === "paid" ? "success" : status === "pending" ? "warning" : "error"} text={status} />
    //   ),
    //   filters: [
    //     { text: "paid", value: "paid" },
    //     { text: "pending", value: "pending" },
    //     { text: "cancel", value: "cancel" },
    //   ],
    //   onFilter: (value, record) => record.status === value,
    //   filteredValue: filteredInfo.status || null,
    // },
  ];

  return (
    <AppLayout
      currentPage={`${t("app-header.title-1")} > ${t("app-header.title-2")} > ${t("app-header.title-4")}`}
      GetIconNav={<HomeOutlined style={{ fontSize: "24px" }} />}
      handleNavigate={() => navigate("/home")}
      screens={screens}
    >
      {/* <MenuButton screens={screens} navigate={navigate} isSportRegisterPage={true} /> */}
      <div style={{ backgroundColor: "#5E72EB", padding: "24px" }}>
        <Row gutter={[16, 16]} align="middle">
          <CategoryButton activeButton={activeButton} handleButtonClick={handleButtonClick} />
          <FormControl
            t={t}
            screens={screens}
            setSearchTerm={setSearchTerm}
            setSelectedProvince={setSelectedProvince}
            setSelectedMonth={setSelectedMonth}
          />
        </Row>
      </div>
      {searchTerm || selectedProvince || selectedMonth ? (
        <RunningEventList
          setSearchTerm={setSearchTerm}
          setSelectedProvince={setSelectedProvince}
          setSelectedMonth={setSelectedMonth}
          user={user}
          screens={screens}
          eventOpen={filteredOpenEvents}
          eventClose={filteredCloseEvents}
        />
      ) : (
        <>
          <ImageEventList t={t} eventId={id} />
          <ButtonMenuRow t={t} activeButton={activeButton} eventId={id} navigate={navigate} />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card
                loading={loading}
                style={{
                  backgroundColor: "rgba(94, 114, 235, 0.2)",
                  borderRadius: 0,
                  borderTop: "15px solid ",
                  borderColor: "#5E72EB",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "none",
                }}
              >
                <Flex justify="end" style={{ marginBottom: 16 }}>
                  <Button onClick={resetFilters}>{t("check-member-page.title-7")}</Button>
                </Flex>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={pagination}
                  onChange={handleTableChange}
                  scroll={{ x: "max-content" }}
                />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </AppLayout>
  );
};
export default MemberCheckRunPage;
