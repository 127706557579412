import React from "react";
import AppLayoutAdmin from "../../../../layout/app-layout-admin";
import AdminLabel from "../../../../components/admin/admin-label";
import MenuButton from "../../../../components/menu-button";
import {
  SearchOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Grid, Row } from "antd";
import CategoryButton from "../../../../components/sport-register/category-button";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../../context/app-provider";

const AddEvent = ({}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { language } = useAppContext();
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = React.useState("run");
  const handleButtonClick = (type) => {
    setActiveButton(type);
    navigate(`/${language}/event/${type}/add-event`);
  };

  return (
    <AppLayoutAdmin currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      {/* Menu Button */}
      <AdminLabel t={t} screens={screens} />
      <MenuButton t={t} screens={screens} navigate={navigate} />
      <div style={{ backgroundColor: "#5E72EB", padding: "24px" }}>
        <Row gutter={[16, 16]} align="middle" justify="center">
          <CategoryButton activeButton={activeButton} handleButtonClick={handleButtonClick} />
        </Row>
      </div>
    </AppLayoutAdmin>
  );
};
export default AddEvent;
