// src/context/AppProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language || "th");

  // เปลี่ยนภาษาตามค่าใน URL
  useEffect(() => {
    const langFromUrl = window.location.pathname.split("/")[1];
    if (["en", "th"].includes(langFromUrl)) {
      setLanguage(langFromUrl);
      i18n.changeLanguage(langFromUrl);
    }
  }, [i18n]);

  // ฟังก์ชันเปลี่ยนภาษา
  // ฟังก์ชันเปลี่ยนภาษา (แก้ไข)
  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);

    // เก็บ path ปัจจุบันและรวมกับ `lang`
    const currentPath = window.location.pathname.split("/").slice(2).join("/"); // path หลังจากภาษา
    window.history.pushState({}, "", `/${lang}/${currentPath}`);
  };

  return <AppContext.Provider value={{ language, changeLanguage }}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
