import React, { useState, useEffect } from "react";
import { Dropdown, Typography, Row, Col, Image, Space, Button, Modal, Avatar } from "antd";
import {
  CaretDownFilled,
  AppstoreFilled,
  SettingFilled,
  BellFilled,
  ContainerFilled,
  UserOutlined,
} from "@ant-design/icons";
import Ticket from "../components/ticket";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import { useAppContext } from "../context/app-provider";
import { fetchAdminInfo, fetchUserInfo } from "../services/fetch-events";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const AppHeader = ({ currentPage, GetIconNav, screens }) => {
  const { user, userAdmin } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { language, changeLanguage } = useAppContext();
  const [selectedTicket, setSelectedTicket] = React.useState("self");
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState(null);
  const [adminInfo, setAdminInfo] = React.useState(null);

  const languageItems = [
    { key: "th", label: "ไทย" },
    { key: "en", label: "English" },
  ];

  const handleLanguageChange = ({ key }) => changeLanguage(key);

  const commonTitle = (
    <Title level={5} style={{ margin: 0 }}>
      {currentPage}
    </Title>
  );

  const renderNavContent = () => (
    <>
      <Col>{GetIconNav}</Col>
      <Col>{commonTitle}</Col>
    </>
  );

  const isScreenSize = (size) => screens[size];

  const getSpaceSize = () => {
    if (isScreenSize("xs")) return 3;
    if (isScreenSize("sm")) return 2;
    if (isScreenSize("md") && isScreenSize("lg")) return 4;
    return "large";
  };

  const handleContainerClick = () => setIsModalVisible(true);

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedTicket("self");
  };

  React.useEffect(() => {
    const loadUserData = async () => {
      try {
        setLoading(true);
        const fetchUserData = await fetchUserInfo(user?.email);
        setUserInfo(fetchUserData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, [user?.email]);

  React.useEffect(() => {
    const loadAdminData = async () => {
      try {
        setLoading(true);
        const fetchUserAdminData = await fetchAdminInfo(userAdmin?.email);
        setAdminInfo(fetchUserAdminData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadAdminData();
  }, [user?.email]);

  return (
    <>
      <Row
        align="middle"
        style={{
          padding: "10px 20px",
          background: "#ffffff",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Col flex="auto">
          <Row align="middle" gutter={[16, 16]}>
            <Col>
              <Image
                src="/main-logo.svg"
                preview={false}
                width={100}
                alt="Left Logo"
                onClick={() => (userAdmin ? navigate(`/${language}/event/run`) : navigate(`/${language}`))} // ใช้ภาษาใน URL
                // onClick={() => (userAdmin ? navigate(`/event/run`) : navigate(`/`))} // ใช้ภาษาใน URL
                style={{ cursor: "pointer" }}
              />
            </Col>
            {!isScreenSize("xs") && !isScreenSize("sm") && renderNavContent()}
            {isScreenSize("lg") && renderNavContent()}
          </Row>
        </Col>
        {user || userAdmin ? (
          <Space size={getSpaceSize()}>
            {/* <Button size="small" type="link">
              <AppstoreFilled style={{ color: "#7D7D7D", fontSize: "24px" }} />
            </Button> */}
            <Row align="middle" gutter={[16, 16]}>
              <Col>
                {language === "th" ? (
                  <Avatar src="/thai-flag.png" onClick={() => changeLanguage("en")} style={{ cursor: "pointer" }} />
                ) : (
                  <Avatar src="/eng-flag.png" onClick={() => changeLanguage("th")} style={{ cursor: "pointer" }} />
                )}
              </Col>
              <Col>
                <Dropdown
                  menu={{
                    items: languageItems,
                    onClick: handleLanguageChange,
                    selectable: true,
                    selectedKeys: [language],
                  }}
                >
                  <Typography.Link strong>
                    <Space>
                      {language === "th" ? "ไทย" : "English"} <CaretDownFilled />
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </Col>
            </Row>
            {user ? (
              <>
                <Button
                  onClick={() => {
                    if (!userAdmin) {
                      navigate(`/${language}/profile`);
                    }
                  }}
                  size="small"
                  type="link"
                >
                  <SettingFilled style={{ fontSize: "24px" }} />
                </Button>
                {/* <Button size="small" type="link" onClick={handleContainerClick}>
                  <ContainerFilled style={{ color: "#7D7D7D", fontSize: "24px" }} />
                </Button> */}
              </>
            ) : null}
            {/* <Button size="small" type="link">
              <BellFilled style={{ color: "#7D7D7D", fontSize: "24px" }} />
            </Button> */}

            <Avatar
              size={50}
              icon={userInfo?.photo === "" || userAdmin?.photo === "" ? <UserOutlined /> : ""}
              src={userInfo?.photo !== "" ? userInfo?.photo : ""}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!userAdmin) {
                  navigate(`/${language}/profile`);
                }
              }}
            />

            {!isScreenSize("xs") && (
              <Title level={5} style={{ margin: 0 }}>
                {userInfo?.fullName || userAdmin?.email}
              </Title>
            )}
          </Space>
        ) : (
          <Row align="middle" gutter={[16, 16]}>
            <Col>
              <Button
                type="link"
                onClick={() => navigate(`/${language}/login`)}
                style={{ color: "#FFFFFF", backgroundColor: "#150E6E" }}
              >
                {t("app-header.button-login")}
                {/* เข้าสู่ระบบ */}
              </Button>
            </Col>
            <Col>
              {language === "th" ? (
                <Avatar src="/thai-flag.png" onClick={() => changeLanguage("en")} style={{ cursor: "pointer" }} />
              ) : (
                <Avatar src="/eng-flag.png" onClick={() => changeLanguage("th")} style={{ cursor: "pointer" }} />
              )}{" "}
            </Col>
            <Col>
              <Dropdown
                menu={{
                  items: languageItems,
                  onClick: handleLanguageChange,
                  selectable: true,
                  selectedKeys: [language],
                }}
              >
                <Typography.Link strong>
                  <Space>
                    {language === "th" ? "ไทย" : "English"} <CaretDownFilled />
                  </Space>
                </Typography.Link>
              </Dropdown>
            </Col>
          </Row>
        )}
      </Row>
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Image src="/banner.png" preview={false} width={50} alt="Logo" style={{ marginRight: "10px" }} />
            <span>OK all Sport and around</span>
          </div>
        }
        open={isModalVisible}
        onCancel={handleModalCancel}
        width={800}
        footer={null}
      >
        <Ticket
          selectedTicket={selectedTicket}
          setSelectedTicket={setSelectedTicket}
          isMobileScreen={isScreenSize("xs")}
          isSmallScreen={isScreenSize("sm")}
          isMediumScreen={isScreenSize("md")}
          isLargeScreen={isScreenSize("lg")}
          isExtraLargeScreen={isScreenSize("xl")}
          isExtraExtraLargeScreen={isScreenSize("xxl")}
        />
      </Modal>
    </>
  );
};

export default AppHeader;
