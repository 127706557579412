import React from "react";
import {
  Card,
  Table,
  Input,
  Button,
  Space,
  Flex,
  Badge,
  Modal,
  Form,
  Image,
  DatePicker,
  Select,
  Grid,
  notification,
} from "antd";
import {
  SearchOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import AdminLabel from "../../../../components/admin/admin-label";
import MenuButton from "../../../../components/menu-button";
import AppLayoutAdmin from "../../../../layout/app-layout-admin";
import { useNavigate, useParams } from "react-router-dom";
import { deleteOrderShirt, fetchEventById, updateOrderShirt } from "../../../../services/fetch-events";
import DownloadExcelOrderShirt from "../../../../components/sport-register/excel-data-list-order-shirt-download";
import { useAppContext } from "../../../../context/app-provider";
import { useTranslation } from "react-i18next";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const { confirm } = Modal;
const { Option } = Select;
const { useBreakpoint } = Grid;

const ManageCheckAndUpdateOrderShirtRunPage = ({}) => {
  const { id } = useParams();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const [filteredInfo, setFilteredInfo] = React.useState({});
  const [pagination, setPagination] = React.useState({ current: 1, pageSize: 10 }); // ตั้งค่าหน้าและขนาดของแต่ละหน้า
  const searchInput = React.useRef(null);
  const [events, setEvents] = React.useState([]);
  const [memberLists, setMemberLists] = React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [selectedMemberKey, setSelectedMemberKey] = React.useState("");
  const [combinedShirtSizes, setCombinedShirtSizes] = React.useState("");
  const { language } = useAppContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  React.useEffect(() => {
    const getEventData = async () => {
      try {
        setLoading(true);
        const { eventData, orderShirt } = await fetchEventById(id, language);
        setEvents(eventData);
        setMemberLists(orderShirt);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getEventData();
  }, [id, language]);

  const combinedDataSource = memberLists.map((member) => {
    const { shirtTotal } = member;
    const combinedShirtSizes = [];

    for (const shirtType in shirtTotal) {
      for (const size in shirtTotal[shirtType]) {
        combinedShirtSizes.push({
          shirtType,
          size,
          quantity: shirtTotal[shirtType][size],
        });
      }
    }

    return {
      ...member,
      combinedShirtSizes,
    };
  });

  const refAcc = Array.from(new Set(memberLists.map((memberList) => memberList.refAcc).filter((name) => name))).map(
    (name) => ({
      text: name,
      value: name,
    })
  );

  const handleEdit = (record) => {
    const shirtSizes = record.shirtTotal;

    // Format the combined shirt sizes
    const combinedSizes = Object.entries(shirtSizes)
      .map(([shirtType, sizes]) => {
        const sizeDetails = Object.entries(sizes)
          .filter(([size, quantity]) => quantity > 0) // กรองเฉพาะขนาดที่มีจำนวนมากกว่า 0
          .map(([size, quantity]) => `${size}: ${quantity}`)
          .join(", ");

        return sizeDetails ? `${shirtType}: ${sizeDetails}` : null; // ไม่แสดงถ้าไม่มีข้อมูล
      })
      .filter(Boolean) // กรองค่า null ออกจากผลลัพธ์
      .join(" | "); // แยกประเภทเสื้อด้วยเครื่องหมาย |

    setCombinedShirtSizes(combinedSizes);

    setSelectedMemberKey(record?.id);
    setIsModalVisible(true);
    form.setFieldsValue({
      date: record.date,
      time: record.time,
      firstName: record.firstName,
      lastName: record.lastName,
      shirtTotal: record.shirtTotal,
      address: record.address,
      refAcc: record.refAcc,
      phone: record.phone,
      delivery: record.delivery,
      totalPrice: record.totalPrice,
      qrImage: record.qrImage,
      status: record.status,
      uid: record.uid,
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalSubmit = () => {
    form.validateFields().then(async (values) => {
      // สร้าง array ของข้อมูลในรูปแบบ array of objects
      const updatedMemberData = {
        date: values.date,
        time: values.time,
        firstName: values.firstName,
        lastName: values.lastName,
        status: values.status,
        shirtTotal: values.shirtTotal,
        address: values.address,
        refAcc: values.refAcc,
        phone: values.phone,
        delivery: values.delivery,
        totalPrice: values.totalPrice,
        qrImage: values.qrImage,
        status: values.status,
        uid: values.uid,
      };
      setLoading(true);

      try {
        const response = await updateOrderShirt("run", id, updatedMemberData); // เปลี่ยนพารามิเตอร์ที่ถูกต้อง
        if (response) {
          setMemberLists((prevMemberLists) =>
            prevMemberLists.map((member) =>
              member.uid === updatedMemberData.uid && member.phone === updatedMemberData.phone
                ? { ...member, ...updatedMemberData }
                : member
            )
          );

          setIsModalVisible(false);
          form.resetFields();
        }
      } catch (error) {
        console.error("Error updating member:", error);
      } finally {
        setLoading(false);
      }
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === "dateTime" ? (
          <div>
            <DatePicker.RangePicker
              style={{ marginBottom: 8, display: "block" }}
              format="YYYY-MM-DD"
              onChange={(dates) => {
                const [start, end] = dates || [];
                setSelectedKeys([
                  start ? start.startOf("day").toISOString() : "",
                  end ? end.endOf("day").toISOString() : "",
                ]);
              }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: "block" }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        )}
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      if (dataIndex === "dateTime") {
        const [start, end] = value;
        const recordDate = dayjs(record.dateTime);
        return (!start || recordDate.isSameOrAfter(dayjs(start))) && (!end || recordDate.isSameOrBefore(dayjs(end)));
      }

      if (dataIndex === "phone") {
        return record.phone ? record.phone.toString().includes(value) : false;
      }

      const fullName = `${record.firstName} ${record.lastName}`.toLowerCase();
      return fullName.includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const resetFilters = () => {
    setFilteredInfo({});
  };

  const handleTableChange = (newPagination, filters) => {
    setPagination(newPagination);
    setFilteredInfo(filters);
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: "แน่ใจว่าจะลบรายการนี้ ?",
      icon: <ExclamationCircleOutlined />,
      content: `ชื่อ: ${record.firstName} ${record.lastName}`,
      okText: "ใช่",
      okType: "danger",
      cancelText: "ยกเลิก",
      onOk() {
        handleDelete(record);
      },
    });
  };

  const handleDelete = async (orderData) => {
    const orderDate = orderData?.date.replace(/\//g, "-"); // แปลงวันที่
    const orderId = `${orderData?.phone}_${orderData?.uid}_${orderDate}_${orderData?.time}`; // สร้าง orderId
    const refAcc = orderData?.refAcc;
    setLoading(true); // เริ่มการโหลด
    try {
      // ลบคำสั่งซื้อเสื้อ
      const result = await deleteOrderShirt("run", id, orderId, refAcc);

      if (result) {
        // อัปเดตรายการสมาชิกทันทีหลังจากลบสำเร็จ
        setMemberLists((prevLists) =>
          prevLists.filter((member) => {
            const memberOrderDate = member.date.replace(/\//g, "-"); // แปลงวันที่ในรายการสมาชิกให้ตรงกัน
            const memberOrderId = `${member.phone}_${member.uid}_${memberOrderDate}_${member.time}`; // สร้าง orderId สำหรับสมาชิกแต่ละคน

            // กรองออกถ้า orderId ตรงกับของที่ถูกลบ
            return memberOrderId !== orderId;
          })
        );
        // แสดงการแจ้งเตือนความสำเร็จ
        notification.success({
          message: "ลบข้อมูลสำเร็จ",
        });
      } else {
        console.error("Failed to delete member");
      }
    } catch (error) {
      console.error("Error deleting member:", error);
    } finally {
      setLoading(false); // หยุดการโหลดไม่ว่าจะสำเร็จหรือล้มเหลว
    }
  };

  const columns = [
    {
      title: "วันที่สมัคร",
      dataIndex: "date",
      key: "date",
      render: (text, record) => `${record.date} ${record.time}`, // รวมชื่อและนามสกุล
      sorter: (a, b) => dayjs(`${a.date} ${a.time}`).unix() - dayjs(`${b.date} ${b.time}`).unix(),
      ...getColumnSearchProps("dateTime"), // Adjusted to match the new filtering logic
      filters: [
        {
          text: "วันนี้",
          value: dayjs().startOf("day").format(),
        },
        {
          text: "สัปดาห์นี้",
          value: dayjs().startOf("week").format(),
        },
        {
          text: "เดือนนี้",
          value: dayjs().startOf("month").format(),
        },
        {
          text: "เดือนที่แล้ว",
          value: dayjs().subtract(1, "month").startOf("month").format(),
          endValue: dayjs().subtract(1, "month").endOf("month").format(),
        },
      ],
      onFilter: (value, record) => {
        // Combine the date and time for filtering
        const combinedDateTime = `${record.date} ${record.time}`;
        const recordDate = dayjs(combinedDateTime);

        if (value === dayjs().subtract(1, "month").startOf("month").format()) {
          const startOfLastMonth = dayjs().subtract(1, "month").startOf("month");
          const endOfLastMonth = dayjs().subtract(1, "month").endOf("month");
          return recordDate.isSameOrAfter(startOfLastMonth, "day") && recordDate.isSameOrBefore(endOfLastMonth, "day");
        }

        const filterDate = dayjs(value).startOf("day");
        return recordDate.isSameOrAfter(filterDate, "day");
      },
      filteredValue: filteredInfo.dateTime || null,
    },
    {
      title: "ชื่อ-สกุล",
      dataIndex: "firstName",
      key: "fullName",
      sorter: (a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
      render: (text, record) => `${record.firstName} ${record.lastName}`, // รวมชื่อและนามสกุล
      ...getColumnSearchProps("fullName"),
      filteredValue: filteredInfo.fullName || null,
    },
    {
      title: "ขนาดเสื้อที่สั่ง",
      dataIndex: "combinedShirtSizes",
      key: "combinedShirtSizes",
      render: (sizes) => (
        <ul>
          {sizes.map((item, index) =>
            item.quantity > 0 ? <li key={index}>{`${item.shirtType} - ${item.size}: ${item.quantity} ตัว`}</li> : null
          )}
        </ul>
      ),
    },
    {
      title: "ที่อยู่",
      dataIndex: "address",
      key: "address",
      render: (address) => (address ? address : "-"),
    },
    {
      title: "อีเมลผู้สั่ง",
      dataIndex: "refAcc",
      key: "refAcc",
      filters: refAcc,
      onFilter: (value, record) => record.refAcc === value,
      filteredValue: filteredInfo.refAcc || null,
      render: (refAcc) => (refAcc ? refAcc : "-"),
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone"),
      filteredValue: filteredInfo.phone || null,
    },
    {
      title: "จัดส่งเสื้อ",
      dataIndex: "delivery",
      key: "delivery",
      render: (text) => (text ? "จัดส่ง" : "ไม่จัดส่ง"),
    },

    {
      title: "ราคาทั้งหมด",
      dataIndex: "totalPrice",
      key: "totalPrice",
    },
    {
      title: "รูปภาพ",
      dataIndex: "qrImage",
      key: "qrImage",
      render: (qrImage) => (
        <Image src={qrImage} alt="Payment" style={{ width: 100, height: 100, objectFit: "cover" }} />
      ),
    },
    {
      title: "สถานะการจ่ายเงิน",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let statusText;
        let badgeStatus;

        switch (status) {
          case "paid":
            statusText = "จ่ายเงินเรียบร้อย";
            badgeStatus = "success";
            break;
          case "pending":
            statusText = "รอการยืนยัน";
            badgeStatus = "warning";
            break;
          case "cancel":
            statusText = "ไม่สำเร็จ";
            badgeStatus = "error";
            break;
          default:
            statusText = status;
            badgeStatus = "default";
        }

        return <Badge status={badgeStatus} text={statusText} />;
      },
      filters: [
        { text: "จ่ายเงินเรียบร้อย", value: "paid" },
        { text: "รอการยืนยัน", value: "pending" },
        { text: "ไม่สำเร็จ", value: "cancel" },
      ],
      onFilter: (value, record) => record.status === value,
      filteredValue: filteredInfo.status || null,
      fixed: "right",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)}></Button>
          <Button type="primary" danger icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record)}></Button>
        </Space>
      ),
      fixed: "right",
    },
  ];

  return (
    <AppLayoutAdmin currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      {/* Menu Button */}
      <AdminLabel t={t} screens={screens} />
      <MenuButton t={t} screens={screens} navigate={navigate} />

      <Card
        title={`ชื่อกิจกรรม : ${events?.Title} (ตรวจสอบและบันทึกสถานะ)`}
        cover={
          <Space style={{ textAlign: "center" }}>
            <img
              src={events?.cover}
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "600px",
                objectFit: "cover",
              }}
            />
          </Space>
        }
        style={{
          borderRadius: 0,
          borderTop: "15px solid ",
          borderColor: "#5E72EB",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "none",
        }}
      >
        <Flex justify="space-between" style={{ marginBottom: 16 }}>
          <DownloadExcelOrderShirt combinedDataSource={combinedDataSource} />
          <Button onClick={resetFilters}>ล้างการฟิลเตอร์</Button>
        </Flex>
        <Table
          loading={loading}
          dataSource={combinedDataSource}
          columns={columns}
          pagination={pagination}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
        />
      </Card>
      <Modal
        title="แก้ไขข้อมูล"
        open={isModalVisible}
        onCancel={handleModalCancel}
        onOk={handleModalSubmit}
        confirmLoading={loading}
      >
        <Form form={form} layout="vertical" name="edit_member">
          <Form.Item name="date" label="วันที่สั่ง">
            <span>{form.getFieldValue("date") || "ไม่มีข้อมูล"}</span>
          </Form.Item>

          <Form.Item name="time" label="เวลาที่สั่ง">
            <span>{form.getFieldValue("time") || "ไม่มีข้อมูล"}</span>
          </Form.Item>

          <Form.Item name="title" label="คำนำหน้า" rules={[{ required: true, message: "กรุณาใส่คำนำหน้า" }]}>
            <Input placeholder="กรุณาใส่คำนำหน้า" />
          </Form.Item>

          <Form.Item name="firstName" label="ชื่อ" rules={[{ required: true, message: "กรุณาใส่ชื่อ" }]}>
            <Input placeholder="กรุณาใส่ชื่อ" />
          </Form.Item>

          <Form.Item name="lastName" label="สกุล" rules={[{ required: true, message: "กรุณาใส่นามสกุล" }]}>
            <Input placeholder="กรุณาใส่นามสกุล" />
          </Form.Item>

          <Form.Item name="shirtTotal" label="ขนาดเสื้อ">
            <span>{combinedShirtSizes || "ไม่มีข้อมูล"}</span>
          </Form.Item>

          <Form.Item name="address" label="ที่อยู่">
            <span>{form.getFieldValue("address") || "-"}</span>
          </Form.Item>

          <Form.Item name="refAcc" label="อีเมลผู้สั่ง">
            <span>{form.getFieldValue("refAcc") || "ไม่มีข้อมูล"}</span>
          </Form.Item>

          <Form.Item name="phone" label="เบอร์โทรศัพท์">
            <span>{form.getFieldValue("phone") || "ไม่มีข้อมูล"}</span>
          </Form.Item>

          <Form.Item name="delivery" hidden>
            <Input style={{ display: "none" }} />
          </Form.Item>

          <Form.Item name="totalPrice" label="ราคา">
            <span>{form.getFieldValue("totalPrice") || "ไม่มีข้อมูล"}</span>
          </Form.Item>

          <Form.Item name="qrImage" hidden>
            <Input style={{ display: "none" }} />
          </Form.Item>

          <Form.Item name="uid" hidden>
            <Input style={{ display: "none" }} />
          </Form.Item>

          <Form.Item
            name="status"
            label="สถานะการจ่ายเงิน"
            rules={[{ required: true, message: "กรุณาเลือกสถานะการจ่ายเงิน" }]}
          >
            <Select placeholder="เลือกสถานะการจ่ายเงิน">
              <Option value="paid">จ่ายเงินเรียบร้อย</Option>
              <Option value="pending">รอการยืนยัน</Option>
              <Option value="cancel">ไม่สำเร็จ</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </AppLayoutAdmin>
  );
};
export default ManageCheckAndUpdateOrderShirtRunPage;
