import React from "react";
import { Layout, Menu, Sider } from "antd";
import AppHeader from "./app-header";
import AppFooter from "./app-footer";
import AppLayoutSider from "./app-layout-sider";

const { Content } = Layout;

const AppLayoutAdmin = ({ children, currentPage, GetIconNav, screens }) => {
  return (
    <Layout>
      <AppHeader currentPage={currentPage} GetIconNav={GetIconNav} screens={screens} />
      <AppLayoutSider screens={screens} children={children} />
      <AppFooter />
    </Layout>
  );
};

export default AppLayoutAdmin;
