import React from "react";
import { Row, Grid, Col, Image } from "antd";
import RegisterForm from "../components/register-form";
import { useNavigate } from "react-router-dom";
import AppLayoutLogin from "../layout/app-layout-login";

const { useBreakpoint } = Grid;

const RegisterPage = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();

  const handleRegister = (values) => {
    // Handle login with backend here
  };

  return (
    <AppLayoutLogin screens={screens}>
      <Row
        justify="center"
        align="middle"
        style={{
          backgroundImage: `url("/main-banner.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          width: "100%",
          borderRadius: 20,
        }}
      >
        <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12} style={{ alignSelf: "start" }}>
          <Image
            src="/paipao-with-thai-word.svg"
            preview={false}
            style={{
              width: "300px",
              height: "auto",
            }}
          />
        </Col>
        <Col xs={22} sm={22} md={20} lg={12} xl={12} xxl={12}>
          <RegisterForm onRegister={handleRegister} navigate={navigate} />
        </Col>
      </Row>
    </AppLayoutLogin>
  );
};

export default RegisterPage;
