// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAdjGXk989fjkcxQgBGFjVEQow99ba3JMs",
  authDomain: "paipao-c4a2c.firebaseapp.com",
  databaseURL: "https://paipao-c4a2c-default-rtdb.firebaseio.com",
  projectId: "paipao-c4a2c",
  storageBucket: "paipao-c4a2c.appspot.com",
  messagingSenderId: "138436275568",
  appId: "1:138436275568:web:e6ed1891e46281accb73b5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
export default app;
