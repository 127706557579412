import React from "react";
import { Row, Col, Card, Button, Typography } from "antd";
import { CalendarOutlined, EnvironmentOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Loading from "../loading-spin";
import { useAppContext } from "../../context/app-provider";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;

const RunningEventList = ({
  activeButton,
  eventOpen,
  eventClose,
  user,
  userAdmin,
  setSearchTerm,
  setSelectedProvince,
  setSelectedMonth,
  loading,
}) => {
  console.log("🚀 ~ activeButton:", activeButton);
  const navigate = useNavigate();
  const { language } = useAppContext();
  const { t } = useTranslation();

  const handleNavigation = (eventId, path) => {
    setSearchTerm("");
    setSelectedProvince("");
    setSelectedMonth("");
    navigate(path);
  };

  const renderButtonsOpenEvent = (event) => {
    const memberCount = event?.member ? Object.keys(event.member).length : 0;

    return (
      <>
        <Col xs={12} sm={5} md={4} lg={7} xl={5} xxl={4}>
          <Button
            size="large"
            block
            type="text"
            style={{ backgroundColor: "#EFE26A" }}
            onClick={() =>
              handleNavigation(
                event.id,
                userAdmin
                  ? `/${language}/event/${activeButton}/detail/${event.id}`
                  : `/${language}/sport-register/${activeButton}/detail/${event.id}`
              )
            }
          >
            <Paragraph strong style={{ margin: 0 }}>
              {t("sport-register-page.button-card-event-1")}
            </Paragraph>
          </Button>
        </Col>
        <Col xs={12} sm={5} md={4} lg={7} xl={5} xxl={4}>
          <Button size="large" block type="text" style={{ backgroundColor: "#2DB2AD" }}>
            <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
              {t("sport-register-page.button-card-event-2")} {memberCount} <UserOutlined />
            </Paragraph>
          </Button>
        </Col>
        <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
          <Button
            size="large"
            block
            type="text"
            style={{ backgroundColor: "#5E72EB" }}
            onClick={() =>
              handleNavigation(
                event.id,
                userAdmin
                  ? `/${language}/event/${activeButton}/manage-check-update/member/${event.id}`
                  : `/${language}/sport-register/${activeButton}/member/${event.id}`
              )
            }
          >
            <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
              {userAdmin ? t("sport-register-page.button-card-event-5") : t("sport-register-page.button-card-event-3")}
            </Paragraph>
          </Button>
        </Col>
        {!userAdmin ? (
          <Col xs={24} sm={24} md={6} lg={14} xl={24} xxl={6}>
            <Button
              size="large"
              block
              type="text"
              style={{ backgroundColor: "#130C63" }}
              onClick={() =>
                handleNavigation(event.id, `/${language}/sport-register/${activeButton}/register/${event.id}`)
              }
            >
              <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
                {t("sport-register-page.button-card-event-4")}
              </Paragraph>
            </Button>
          </Col>
        ) : (
          <Col xs={24} sm={24} md={6} lg={14} xl={24} xxl={6}>
            <Button
              size="large"
              block
              type="text"
              style={{ backgroundColor: "#130C63" }}
              onClick={() =>
                handleNavigation(
                  event.id,
                  `/${language}/event/${activeButton}/manage-check-update/order-shirt/${event.id}`
                )
              }
            >
              <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
                {t("sport-register-page.button-card-event-6")}
              </Paragraph>
            </Button>
          </Col>
        )}
      </>
    );
  };
  const renderButtonsCloseEvent = (event) => {
    const memberCount = event?.member ? Object.keys(event.member).length : 0;

    return (
      <>
        <Col xs={12} sm={5} md={4} lg={7} xl={5} xxl={4}>
          <Button
            size="large"
            block
            type="text"
            style={{ backgroundColor: "#EFE26A" }}
            onClick={() =>
              handleNavigation(
                event.id,
                userAdmin
                  ? `/${language}/event/${activeButton}/detail/${event.id}`
                  : `/${language}/sport-register/${activeButton}/detail/${event.id}`
              )
            }
          >
            <Paragraph strong style={{ margin: 0 }}>
              {t("sport-register-page.button-card-event-1")}
            </Paragraph>
          </Button>
        </Col>
        <Col xs={12} sm={5} md={4} lg={7} xl={5} xxl={4}>
          <Button size="large" block type="text" style={{ backgroundColor: "#2DB2AD" }}>
            <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
              {t("sport-register-page.button-card-event-2")} {memberCount} <UserOutlined />
            </Paragraph>
          </Button>
        </Col>
        <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
          <Button
            size="large"
            block
            type="text"
            style={{ backgroundColor: "#5E72EB" }}
            onClick={() =>
              handleNavigation(
                event.id,
                userAdmin
                  ? `/${language}/event/${activeButton}/manage-check-update/member/${event.id}`
                  : `/${language}/sport-register/${activeButton}/member/${event.id}`
              )
            }
          >
            <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
              {userAdmin ? t("sport-register-page.button-card-event-5") : t("sport-register-page.button-card-event-3")}
            </Paragraph>
          </Button>
        </Col>
        {!userAdmin ? (
          <Col xs={24} sm={24} md={6} lg={14} xl={24} xxl={6}>
            <Button
              size="large"
              block
              type="text"
              disabled
              style={{ backgroundColor: "#130C63" }}
              onClick={() =>
                handleNavigation(event.id, `/${language}/sport-register/${activeButton}/register/${event.id}`)
              }
            >
              <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
                {t("sport-register-page.button-card-event-4-1")}
              </Paragraph>
            </Button>
          </Col>
        ) : (
          <Col xs={24} sm={24} md={6} lg={14} xl={24} xxl={6}>
            <Button
              size="large"
              block
              type="text"
              style={{ backgroundColor: "#130C63" }}
              onClick={() =>
                handleNavigation(
                  event.id,
                  `/${language}/event/${activeButton}/manage-check-update/order-shirt/${event.id}`
                )
              }
            >
              <Paragraph strong style={{ margin: 0, color: "#FFFFFF" }}>
                {t("sport-register-page.button-card-event-6")}
              </Paragraph>
            </Button>
          </Col>
        )}
      </>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Row justify="center" align="middle" gutter={[32, 32]} style={{ marginTop: "20px" }}>
      {eventOpen?.map((event) => (
        <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={12} key={event.id}>
          <Card
            cover={
              <div style={{ aspectRatio: "16/9", overflow: "hidden" }}>
                <img src={event.cover} alt={event.title} style={{ width: "100%", height: "100%" }} />
              </div>
            }
          >
            <Card.Meta
              title={event?.Title}
              description={`${t("sport-register-page.end-event")} ${event?.end}`}
              style={{ paddingBottom: "10px" }}
            />
            <Row gutter={[8, 16]}>
              <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
                <Paragraph level={5} style={{ margin: 0 }}>
                  <EnvironmentOutlined style={{ color: "red" }} /> {event.location}
                </Paragraph>
                <Paragraph level={5} style={{ margin: 0 }}>
                  <CalendarOutlined /> {event.date}
                </Paragraph>
              </Col>
              {renderButtonsOpenEvent(event)}
            </Row>
          </Card>
        </Col>
      ))}
      {eventClose?.map((event) => (
        <Col xs={23} sm={23} md={23} lg={11} xl={11} xxl={12} key={event.id}>
          <Card
            cover={
              <div style={{ aspectRatio: "16/9", overflow: "hidden" }}>
                <img src={event.cover} alt={event.title} style={{ width: "100%", height: "100%" }} />
              </div>
            }
          >
            <Card.Meta
              title={event?.Title}
              description={`${t("sport-register-page.end-event")} ${event?.end}`}
              style={{ paddingBottom: "10px" }}
            />
            <Row gutter={[8, 16]}>
              <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
                <Paragraph level={5} style={{ margin: 0 }}>
                  <EnvironmentOutlined style={{ color: "red" }} /> {event.location}
                </Paragraph>
                <Paragraph level={5} style={{ margin: 0 }}>
                  <CalendarOutlined /> {event.date}
                </Paragraph>
              </Col>
              {renderButtonsCloseEvent(event)}
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default RunningEventList;
