import React from "react";
import { Row, Card, Grid, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import AppLayout from "../../../../layout/app-layout";
import { useNavigate, useParams } from "react-router-dom";
import ImageEventList from "../../../../components/sport-register/image-event-list";
import ButtonMenuRow from "../../../../components/sport-register/button-menu-row";
import MenuButton from "../../../../components/menu-button";
import CategoryButton from "../../../../components/sport-register/category-button";
import FormControl from "../../../../components/sport-register/form-control";
import { fetchEventById, fetchEventData, fetchRunEvents } from "../../../../services/fetch-events";
import RunningEventList from "../../../../components/sport-register/running-event-list";
import { useAppContext } from "../../../../context/app-provider";
import { useTranslation } from "react-i18next";

const { useBreakpoint } = Grid;

const DetailEventBikePage = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const { language } = useAppContext();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [activeButton, setActiveButton] = React.useState("bicycle");
  const [events, setEvents] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedProvince, setSelectedProvince] = React.useState("");
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [eventClose, setEventClose] = React.useState(null);
  const [eventOpen, setEventOpen] = React.useState(null);

  React.useEffect(() => {
    const getEventData = async () => {
      try {
        setLoading(true);
        const { eventData } = await fetchEventById(id, language);
        setEvents(eventData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getEventData();
  }, [id, language]);

  React.useEffect(() => {
    const loadEvents = async () => {
      try {
        setLoading(true);
        const { closeEventData, openEventData } = await fetchRunEvents("bicycle", language); // เรียก API พร้อมพารามิเตอร์
        setEventClose(closeEventData);
        setEventOpen(openEventData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadEvents();
  }, []);

  const filteredOpenEvents = eventOpen?.filter((event) => {
    const eventMonth = event?.date?.split("/")[1];
    return (
      event?.Title?.toLowerCase().includes(searchTerm?.toLowerCase()) &&
      (selectedProvince === "" || event.location === selectedProvince) &&
      (selectedMonth === "" || eventMonth === selectedMonth)
    );
  });

  const filteredCloseEvents = eventClose?.filter((event) => {
    const eventMonth = event?.date?.split("/")[1];
    return (
      event?.Title?.toLowerCase().includes(searchTerm?.toLowerCase()) &&
      (selectedProvince === "" || event.location === selectedProvince) &&
      (selectedMonth === "" || eventMonth === selectedMonth)
    );
  });

  React.useEffect(() => {
    const getEventData = async () => {
      try {
        setLoading(true);
        const { eventData } = await fetchEventById(id);
        setEvents(eventData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getEventData();
  }, [id]);

  const handleButtonClick = (type) => {
    setActiveButton(type);
    navigate(`/${language}/sport-register/${type}`);
  };

  return (
    <AppLayout
      currentPage={`${t("app-header.title-1")} > ${t("app-header.title-2")} > ${t("app-header.title-3")}`}
      GetIconNav={<HomeOutlined style={{ fontSize: "24px" }} />}
      handleNavigate={() => navigate("/home")}
      screens={screens}
      user={user}
    >
      {/* <MenuButton screens={screens} navigate={navigate} isSportRegisterPage={true} /> */}
      <div style={{ backgroundColor: "#5E72EB", padding: "24px" }}>
        <Row gutter={[16, 16]} align="middle">
          <CategoryButton activeButton={activeButton} handleButtonClick={handleButtonClick} />
          <FormControl
            t={t}
            screens={screens}
            setSearchTerm={setSearchTerm}
            setSelectedProvince={setSelectedProvince}
            setSelectedMonth={setSelectedMonth}
          />
        </Row>
      </div>
      {searchTerm || selectedProvince || selectedMonth ? (
        <RunningEventList
          setSearchTerm={setSearchTerm}
          setSelectedProvince={setSelectedProvince}
          setSelectedMonth={setSelectedMonth}
          user={user}
          screens={screens}
          eventOpen={filteredOpenEvents}
          eventClose={filteredCloseEvents}
        />
      ) : (
        <>
          <ImageEventList t={t} eventId={id} />
          <ButtonMenuRow t={t} activeButton={activeButton} eventId={id} navigate={navigate} />
          <Card
            loading={loading}
            style={{
              backgroundColor: "rgba(239, 226, 106, 0.2)",
              borderRadius: 0,
              borderTop: "15px solid ",
              borderColor: "#EFE26A",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: 0,
                paddingTop: "122.7273%",
                paddingBottom: 0,
                boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
                marginTop: "1.6em",
                marginBottom: "0.9em",
                overflow: "hidden",
                borderRadius: "8px",
                willChange: "transform",
              }}
            >
              <iframe
                loading="lazy"
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                src={events?.detail}
                allowFullScreen
              />
            </div>
          </Card>
        </>
      )}
    </AppLayout>
  );
};

export default DetailEventBikePage;
