import { Card, Col, Image, Row, Space, Typography } from "antd";

const AdminLabel = ({ t, screens }) => {
  return (
    <Row justify="space-around" align="middle" gutter={[8, 8]}>
      <Col xs={23} sm={11} md={11} lg={11} xl={11} xxl={11}>
        <Card style={{ borderRadius: "20px", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)" }}>
          <Row justify="center" gutter={[8, 8]} align="middle">
            <Col xs={23} sm={11} md={11} lg={11} xl={11} xxl={11}>
              <Image preview={false} src="/admin-banner-logo.svg" />
            </Col>
            <Col xs={23} sm={11} md={11} lg={11} xl={11} xxl={11}>
              <Typography.Paragraph strong>กิจการ : admin paipao</Typography.Paragraph>
              <Typography.Paragraph strong>คะแนนผู้ขาย</Typography.Paragraph>
              <Typography.Paragraph strong>เลเวล </Typography.Paragraph>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={23} sm={11} md={11} lg={11} xl={11} xxl={11}>
        <Card style={{ borderRadius: "20px", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)" }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            การแจ้งเตือน
          </Typography.Title>
          <Typography.Paragraph>💥แผนผังเพิ่มโอกาสรับโบนัสพิเศษต่างๆ Mid Month 15 เมษายนนี้!</Typography.Paragraph>
          <Typography.Paragraph>ซื้อสินค้าในโปรโมชั่นพิเศษ เพียง 499 บาทต่อ 1 ชิ้น</Typography.Paragraph>
          <Typography.Paragraph>
            💥โปรโมชั่น: เข้าร่วมรับการลด 20% ต่อ 1 สัปดาห์ ระยะเวลาสิ้นสุด วันที่ 11 เมษายน 67
          </Typography.Paragraph>
        </Card>
      </Col>
    </Row>
  );
};

export default AdminLabel;
