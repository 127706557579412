import React from "react";
import { Row, Grid, Card, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import RunningEventList from "../../../components/sport-register/running-event-list";
import AppLayout from "../../../layout/app-layout";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../../components/menu-button";
import CategoryButton from "../../../components/sport-register/category-button";
import FormControl from "../../../components/sport-register/form-control";

const { useBreakpoint } = Grid;

const ThaiShopPage = ({ user }) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = React.useState("run");

  return (
    <AppLayout
      currentPage="หน้าแรก > ร้านค้าไทย"
      GetIconNav={<HomeOutlined style={{ fontSize: "24px" }} />}
      handleNavigate={() => navigate("/home")}
      screens={screens}
      user={user}
    >
      <MenuButton screens={screens} navigate={navigate} isThaiShopPage={true} />
      {/* <div style={{ backgroundColor: "#150E6E", padding: "24px" }}> */}
      {/* <Row gutter={[16, 16]} align="middle">
          <CategoryButton activeButton={activeButton} />
          <FormControl screens={screens} />
        </Row> */}
      {/* </div> */}
      {/* <RunningEventList screens={screens} /> */}
      <div
      style={{
        backgroundColor: "#2DB2AD",
        padding: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh", // Full viewport height
      }}
    >
      <Card
        style={{ maxWidth: "100%", width: "100%", textAlign: "center", backgroundColor: "#FFFFFF" }}
        bordered={false}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
            paddingTop: '106.2%',
            boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
            marginTop: '1.6em',
            marginBottom: '0.9em',
            overflow: 'hidden',
            borderRadius: '8px',
            willChange: 'transform',
          }}
        >
          <iframe
            loading="lazy"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%', // Ensure iframe takes full height of the container
              top: 0,
              left: 0,
              border: 'none',
              padding: 0,
              margin: 0
            }}
            src="https://www.canva.com/design/DAGSznMt4II/xTYEQxsFu6HzBKnhdnS0Rw/view?embed"
            allowFullScreen
            title="Canva Design"
          />
        </div>
      </Card>
    </div>
    </AppLayout>
  );
};

export default ThaiShopPage;
