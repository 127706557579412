import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Spin } from "antd";
import { useAuth } from "../context/auth-context";

const AuthRoute = () => {
  const { user, loading, userAdmin } = useAuth();

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20%" }}>
        <Spin size="large" />
      </div>
    );
  }

  if (user) {
    return <Navigate to="/" replace />;
  } else if (userAdmin) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default AuthRoute;
