import React, { createContext, useContext, useState } from "react";
import { auth } from "../components/firebase";
import { fetchAdminInfo, fetchUserInfo } from "../services/fetch-events";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userAdmin, setUserAdmin] = useState(null);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (userLogin) => {
      if (userLogin?.email) {
        setLoading(true); // Set loading to true when starting to fetch user info

        try {
          const userInfo = await fetchUserInfo(userLogin?.email);
          const adminInfo = await fetchAdminInfo(userLogin?.email);

          if (userInfo !== "fail") {
            setUser(userLogin);
          } else if (adminInfo !== "fail") {
            setUserAdmin(userLogin);
          }
        } catch (error) {
          console.error("Error fetching document:", error);
        } finally {
          setLoading(false); // Ensure loading is set to false after fetching is done
        }
      } else {
        setUser(null);
        setUserAdmin(null);
        setLoading(false); // Handle case where there is no user
      }
    });

    return () => unsubscribe(); // Clean up subscription on component unmount
  }, []);

  return <AuthContext.Provider value={{ user, userAdmin, loading, setUser }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
