import React, { useState, useEffect } from "react";
import {
  Card,
  Avatar,
  Typography,
  Descriptions,
  Space,
  Row,
  Col,
  Image,
  Form,
  Button,
  Input,
  Upload,
  Flex,
  Grid,
  notification,
} from "antd";
import { PhoneOutlined, UserOutlined, MailOutlined, EditOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { auth } from "../components/firebase";
import AppLayoutLogin from "../layout/app-layout-login";
import { editUser, fetchUserInfo, postImageSlip } from "../services/fetch-events";
import { useAuth } from "../context/auth-context";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { useBreakpoint } = Grid;

const Profile = ({}) => {
  const { user } = useAuth();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [userInfo, setUserInfo] = React.useState(null);

  React.useEffect(() => {
    const loadUserData = async () => {
      try {
        setLoading(true);
        const fetchUserData = await fetchUserInfo(user?.email);
        setUserInfo(fetchUserData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, [user?.email]);

  async function handleLogout() {
    try {
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  }

  const handleSave = async () => {
    setLoading(true);
    try {
      // Validate and get the form values
      const { fullName, call } = await form.validateFields();

      let photo = "";

      if (fileList.length > 0) {
        await new Promise(async (resolve, reject) => {
          const fileName = `userImage/${userInfo.email}_profile`;
          const reader = new FileReader();
          reader.readAsDataURL(fileList[0].originFileObj);
          reader.onload = async (e) => {
            const rawData = e.target.result;
            const fileURL = await postImageSlip(fileName, rawData);
            if (fileURL) {
              photo = fileURL;
              resolve(); // อัปโหลดสำเร็จ
            } else {
              console.error("Failed to upload the image slip.");
              reject(new Error("Failed to upload image")); // อัปโหลดไม่สำเร็จ
            }
          };
          reader.onerror = () => {
            reject(new Error("Error reading file")); // ข้อผิดพลาดในการอ่านไฟล์
          };
        });
      }
      // Prepare user data
      const userData = {
        fullName,
        email: userInfo?.email,
        password: userInfo?.password,
        call,
        photo: photo || userInfo?.photo,
        userID: userInfo?.userID,
        myEvents: userInfo?.myEvents,
        providers: userInfo?.providers,
      };

      // Call the editUser API
      const response = await editUser(userData);
      if (response) {
        notification.success({ message: t("profile-page.title-4") });
        setIsEditing(false); // Exit editing mode

        // Update local state to reflect changes immediately
        setUserInfo((prevUserInfo) => {
          const newUserInfo = {
            ...prevUserInfo,
            fullName,
            call,
            photo: userData.photo || prevUserInfo.photo,
          };
          return newUserInfo;
        });

        // Set form values back to original values after saving
        form.setFieldsValue({
          fullName,
          call,
        });
      } else {
        notification.error({ message: t("profile-page.title-5") });
      }
    } catch (error) {
      console.error("Error saving data:", error);
      notification.error({ message: t("profile-page.title-5") });
    } finally {
      setLoading(false);
    }
  };

  const handleClearImage = () => {
    setFileList([]);
  };

  const handleEdit = () => {
    setIsEditing(true);
    form.setFieldsValue({
      fullName: userInfo.fullName,
      call: userInfo.call,
    });
  };

  return (
    <AppLayoutLogin
      currentPage={t("app-header.title-6")}
      screens={screens}
      GetIconNav={<UserOutlined style={{ fontSize: "24px" }} />}
    >
      <Row
        justify="space-around"
        align="middle"
        style={{
          backgroundImage: `url("/main-banner.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          width: "100%",
          borderRadius: 20,
          padding: "20px 0px 20px 0px",
        }}
      >
        <Col xs={20} sm={20} md={24} lg={24} xl={24} xxl={24}>
          <Card
            loading={loading}
            style={{
              maxWidth: "600px",
              margin: "0 auto",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Form.Item name="qrImage" layout="horizontal">
                  {isEditing && fileList.length > 0 ? (
                    <div style={{ position: "relative", width: 128, height: 128 }}>
                      <Avatar
                        src={URL.createObjectURL(fileList[0].originFileObj)}
                        alt="Image preview"
                        preview={false}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                      />
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={handleClearImage}
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          border: "none",
                        }}
                      />
                    </div>
                  ) : (
                    <Avatar size={128} src={userInfo?.photo ? userInfo?.photo : ""} icon={<UserOutlined />} />
                  )}

                  {/* Show upload input only in edit mode */}
                  {isEditing && (
                    <Upload
                      listType="picture"
                      name="files"
                      maxCount={1}
                      onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                      showUploadList={false}
                      customRequest={() => {
                        /* no action required */
                      }}
                    >
                      <Button
                        icon={<EditOutlined style={{ fontSize: "18px" }} />}
                        disabled={loading} // Place the disabled prop outside of icon
                      >
                        {t("profile-page.title-6")}
                      </Button>
                    </Upload>
                  )}
                </Form.Item>

                <Title level={2} style={{ margin: 0 }}>
                  {userInfo?.fullName}
                </Title>
              </div>
              <Form form={form} layout="vertical">
                <Descriptions layout="vertical" column={1}>
                  {/* fullName */}
                  <Descriptions.Item
                    label={
                      <Space>
                        <UserOutlined />
                        <span> {t("profile-page.title-1")}</span>
                      </Space>
                    }
                  >
                    {isEditing ? (
                      <Form.Item name="fullName" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    ) : (
                      userInfo?.fullName
                    )}
                  </Descriptions.Item>

                  {/* Call */}
                  <Descriptions.Item
                    label={
                      <Space>
                        <PhoneOutlined />
                        <span> {t("profile-page.title-2")}</span>
                      </Space>
                    }
                  >
                    {isEditing ? (
                      <Form.Item name="call" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    ) : (
                      userInfo?.call
                    )}
                  </Descriptions.Item>

                  {/* Email (ไม่สามารถแก้ไขได้) */}
                  <Descriptions.Item
                    label={
                      <Space>
                        <MailOutlined />
                        <span> {t("profile-page.title-3")}</span>
                      </Space>
                    }
                  >
                    {userInfo?.email}
                  </Descriptions.Item>
                </Descriptions>
              </Form>

              <Flex justify="space-around" wrap gap="middle">
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    if (isEditing) {
                      handleSave();
                    } else {
                      handleEdit();
                    }
                  }}
                >
                  {isEditing ? t("profile-page.button-5") : t("profile-page.button-1")}
                </Button>

                {isEditing && (
                  <Button type="default" onClick={() => setIsEditing(false)} disabled={loading}>
                    {t("profile-page.button-6")}
                  </Button>
                )}
                <Button type="primary" onClick={() => navigate("my-event")}>
                  {t("profile-page.button-2")}
                </Button>
                <Button type="primary" onClick={() => navigate("my-order")}>
                  {t("profile-page.button-3")}
                </Button>
                <Button type="primary" onClick={handleLogout} disabled={loading}>
                  {t("profile-page.button-4")}
                </Button>
              </Flex>
            </Space>
          </Card>
        </Col>
      </Row>
    </AppLayoutLogin>
  );
};

export default Profile;
