import React from "react";
import { Row, Grid } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import RunningEventList from "../../../../components/sport-register/running-event-list";
import AppLayout from "../../../../layout/app-layout";
import { useNavigate } from "react-router-dom";
import MenuButton from "../../../../components/menu-button";
import CategoryButton from "../../../../components/sport-register/category-button";
import FormControl from "../../../../components/sport-register/form-control";
import { useAppContext } from "../../../../context/app-provider";
import { useTranslation } from "react-i18next";

const { useBreakpoint } = Grid;

const SportRegisterSportPage = ({ user }) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = React.useState("sport");
  const { language } = useAppContext;
  const { t } = useTranslation();

  const handleButtonClick = (type) => {
    setActiveButton(type);

    if (type === "run") {
      navigate(`/${language}/sport-register/run`);
    } else if (type === "bicycle") {
      navigate(`/${language}/sport-register/bicycle`);
    } else if (type === "sport") {
      navigate(`/${language}/sport-register/sport`);
    }
  };

  return (
    <AppLayout
      currentPage={`${t("app-header.title-1")} > ${t("app-header.title-2")}`}
      GetIconNav={<HomeOutlined style={{ fontSize: "24px" }} />}
      handleNavigate={() => navigate("/home")}
      screens={screens}
      user={user}
    >
      {/* <MenuButton screens={screens} navigate={navigate} isSportRegisterPage={true} /> */}
      <div style={{ backgroundColor: "#5E72EB", padding: "24px" }}>
        <Row gutter={[16, 16]} align="middle">
          <CategoryButton activeButton={activeButton} handleButtonClick={handleButtonClick} />
          <FormControl screens={screens} t={t} />
        </Row>
      </div>
      <RunningEventList screens={screens} />
    </AppLayout>
  );
};

export default SportRegisterSportPage;
