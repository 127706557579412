import React from "react";
import { Layout } from "antd";
import AppHeader from "./app-header";
import AppFooter from "./app-footer";

const { Content } = Layout;

const AppLayout = ({ children, currentPage, GetIconNav, user, handleNavigate, screens }) => (
  <Layout>
    <AppHeader
      currentPage={currentPage}
      GetIconNav={GetIconNav}
      user={user}
      handleNavigate={handleNavigate}
      screens={screens}
    />
    <Content style={{ maxWidth: "1480px", width: "100%", margin: "0 auto" }}>{children}</Content>
    <AppFooter />
  </Layout>
);

export default AppLayout;
