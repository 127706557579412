import React from "react";
import { Button } from "antd";
import * as XLSX from "xlsx";

const DownloadExcelOrderShirt = ({ combinedDataSource }) => {
  const handleDownloadExcel = () => {
    // Filter members with status "paid"
    const filteredMembers = combinedDataSource.filter((member) => member.status === "paid");

    // Format the filtered members for Excel export
    const formattedData = filteredMembers.map((member) => ({
      วันที่สั่ง: member.date,
      เวลาที่สั่ง: member.time,
      ชื่อ: member.firstName,
      สกุล: member.lastName,
      ขนาดเสื้อที่สั่ง: member.combinedShirtSizes
        .filter((size) => size.quantity > 0) // Filter out sizes with quantity 0
        .map((size) => `${size.shirtType} - ${size.size}: ${size.quantity} ตัว`)
        .join(", "),
      อีเมลผู้สั่ง: member.refAcc,
      จัดส่งเสื้อ: member.delivery ? "จัดส่ง" : "ไม่จัดส่ง",
      เบอร์โทรศัพท์: member.phone,
      ค่าสมัคร: member.totalPrice,
      สถานะการจ่ายเงิน: "จ่ายเงินเรียบร้อย",
      รูปภาพสลิป: member.qrImage || "ไม่มีรูปภาพ", // Use logical OR for fallback
    }));

    // Check if there are any members to download
    if (formattedData.length === 0) {
      alert("ไม่มีข้อมูลสมาชิกที่จ่ายเงินเพื่อดาวน์โหลด");
      return;
    }

    // Create a worksheet from formattedData
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Paid Members");

    // Write the workbook to a file and initiate download
    XLSX.writeFile(workbook, "รายชื่อผู้สั่งเสื้อ.xlsx");
  };

  return (
    <Button type="primary" onClick={handleDownloadExcel}>
      ดาวน์โหลดข้อมูลสมาชิกที่จ่ายเงินเป็น Excel
    </Button>
  );
};

export default DownloadExcelOrderShirt;
