import React from "react";
import { Card, Typography, Row, Col, Button, Space, Descriptions, Divider, Collapse, Input, Grid, Spin } from "antd";
import {
  CalendarOutlined,
  EnvironmentOutlined,
  UserOutlined,
  PhoneOutlined,
  CheckOutlined,
  HomeOutlined,
  SkinOutlined,
  WalletOutlined,
  TruckOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { fetchMyRegisterEvents, fetchUserInfo, updateOrderShirt } from "../../services/fetch-events";
import { useAuth } from "../../context/auth-context";
import AppLayoutLogin from "../../layout/app-layout-login";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/app-provider";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const OrderShirtPage = ({}) => {
  const { user } = useAuth();
  const screens = useBreakpoint();
  const { language } = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [loadingCard, setLoadingCard] = React.useState(false);
  const [editingMemberId, setEditingMemberId] = React.useState(null);
  const [memberData, setMemberData] = React.useState({});
  const [eventClose, setEventClose] = React.useState(null);
  const [eventOpen, setEventOpen] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const { closeEventData, openEventData } = await fetchMyRegisterEvents(user?.uid, user?.email, language);

      if (closeEventData) {
        setEventClose(closeEventData);
      }

      if (openEventData) {
        setEventOpen(openEventData);
      }
    };

    fetchData();
  }, [user?.uid, user?.email, language]);

  const mapShirtSize = (sizeKey) => {
    let displaySize = sizeKey; // ค่าเริ่มต้นเป็น sizeKey

    switch (sizeKey) {
      case "XXL":
        displaySize = "2XL";
        break;
      case "XXXL":
        displaySize = "3XL";
        break;
      case "XXXXL":
        displaySize = "4XL";
        break;
      case "XXXXXL":
        displaySize = "5XL";
        break;
      case "XXS":
        displaySize = "2XS";
        break;
      default:
        break;
    }

    return displaySize;
  };

  const handleEdit = (memberId, member) => {
    setEditingMemberId(memberId); // Now editing the individual member
    setMemberData(member);
  };

  const handleSave = async (eventId, memberId) => {
    setLoading(true);
    try {
      const updatedMemberData = {
        date: memberData.date,
        time: memberData.time,
        uid: user?.uid,
        address: memberData.address,
        delivery: memberData.delivery,
        firstName: memberData.firstName,
        lastName: memberData.lastName,
        qrImage: memberData.qrImage,
        phone: memberData.phone,
        shirtTotal: memberData.shirtTotal,
        totalPrice: memberData.totalPrice,
        status: "pending",
        refAcc: user?.email,
        groupName: memberData.groupName,
      };

      // Save the data for the specific member
      await updateOrderShirt("run", eventId, { ...updatedMemberData });
      setEventOpen((prevEvents) =>
        prevEvents.map((event) => {
          if (event.id === eventId) {
            return {
              ...event,
              shirtOrder: {
                ...event.shirtOrder,
                [memberId]: {
                  ...event.shirtOrder[memberId],
                  ...updatedMemberData, // Update only the necessary fields
                },
              },
            };
          }
          return event;
        })
      );

      setEditingMemberId(null); // Exit editing mode for the member
    } catch (error) {
      console.error("Save failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setMemberData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <AppLayoutLogin
      currentPage={`${t("app-header.title-6")} > ${t("app-header.title-8")}`}
      screens={screens}
      GetIconNav={<UserOutlined style={{ fontSize: "24px" }} />}
    >
      <Row
        justify="space-around"
        align="middle"
        style={{
          backgroundImage: `url("/main-banner.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          width: "100%",
          borderRadius: 20,
          padding: "20px 0px",
        }}
      >
        {loadingCard ? (
          <Spin
            size="large"
            indicator={<LoadingOutlined style={{ fontSize: 48, display: "block", margin: "20px auto" }} spin />}
          />
        ) : (
          <Col span={24}>
            {eventOpen?.length > 0 && eventOpen[0]?.shirtOrder?.length > 0 ? (
              eventOpen.map((event) => (
                <Card
                  key={event.id}
                  type="inner"
                  title={t("my-order-page.title-15")}
                  cover={
                    <div style={{ aspectRatio: "16/9", overflow: "hidden" }}>
                      <img src={event.cover} alt={event.title} style={{ width: "100%", height: "100%" }} />
                    </div>
                  }
                  style={{
                    maxWidth: "768px",
                    margin: "0 auto",
                    marginBottom: "16px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                  }}
                >
                  <Card.Meta title={event.title} style={{ paddingBottom: "10px" }} />
                  <Row>
                    <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
                      <Paragraph style={{ margin: 0 }}>
                        <EnvironmentOutlined style={{ color: "red" }} /> {event.location} <CalendarOutlined />{" "}
                        {event.date}
                      </Paragraph>
                    </Col>
                  </Row>

                  <Collapse style={{ marginTop: "16px" }} expandIconPosition="end">
                    <Panel header={t("my-order-page.title-16")} key="1">
                      {Object.entries(event.shirtOrder).map(([memberId, member]) => (
                        <React.Fragment key={memberId}>
                          <Space direction="vertical" size="large" style={{ width: "100%", marginTop: "16px" }}>
                            <Descriptions layout="vertical" column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 5 }}>
                              <Descriptions.Item
                                label={
                                  <Space>
                                    <CalendarOutlined />
                                    <span>{t("my-order-page.title-6")}</span>
                                  </Space>
                                }
                              >
                                {member.date} {member.time}
                              </Descriptions.Item>

                              <Descriptions.Item
                                label={
                                  <Space>
                                    <UserOutlined />
                                    <span>{t("my-order-page.title-28")}</span>
                                  </Space>
                                }
                              >
                                {member.title}
                              </Descriptions.Item>

                              <Descriptions.Item
                                label={
                                  <Space>
                                    <UserOutlined />
                                    <span>{t("my-order-page.title-7")}</span>
                                  </Space>
                                }
                              >
                                {editingMemberId === memberId ? (
                                  <Input
                                    value={memberData.firstName}
                                    onChange={(e) => handleChange("firstName", e.target.value)}
                                  />
                                ) : (
                                  member.firstName
                                )}
                              </Descriptions.Item>

                              <Descriptions.Item
                                label={
                                  <Space>
                                    <UserOutlined />
                                    <span>{t("my-order-page.title-8")}</span>
                                  </Space>
                                }
                              >
                                {editingMemberId === memberId ? (
                                  <Input
                                    value={memberData.lastName}
                                    onChange={(e) => handleChange("lastName", e.target.value)}
                                  />
                                ) : (
                                  member.lastName
                                )}
                              </Descriptions.Item>

                              {/* Phone Number */}
                              <Descriptions.Item
                                label={
                                  <Space>
                                    <PhoneOutlined />
                                    <span>{t("my-order-page.title-9")}</span>
                                  </Space>
                                }
                              >
                                {member.phone}
                              </Descriptions.Item>

                              {/* Address (not editable) */}
                              <Descriptions.Item
                                label={
                                  <Space>
                                    <HomeOutlined />
                                    <span>{t("my-order-page.title-10")}</span>
                                  </Space>
                                }
                              >
                                {member.address === "" ? (
                                  t("my-order-page.title-17")
                                ) : editingMemberId === memberId ? (
                                  <Input
                                    value={memberData.address}
                                    onChange={(e) => handleChange("address", e.target.value)}
                                  />
                                ) : (
                                  member.address
                                )}
                              </Descriptions.Item>

                              {/* Shirt Size */}
                              {Object.entries(member.shirtTotal).map(([shirtType, sizes]) => (
                                <Descriptions.Item
                                  key={shirtType}
                                  label={
                                    <Space>
                                      <SkinOutlined />
                                      <span>
                                        {t("my-order-page.title-11")} ({shirtType.replace("shirt", "").toUpperCase()})
                                      </span>
                                    </Space>
                                  }
                                >
                                  {Object.entries(sizes)
                                    .filter(([size, quantity]) => quantity > 0)
                                    .map(([size, quantity]) => (
                                      <div key={size}>
                                        {t("my-order-page.title-18")} {mapShirtSize(size)} {t("my-order-page.title-19")}
                                        {quantity} {t("my-order-page.title-20")}
                                      </div>
                                    )).length > 0
                                    ? Object.entries(sizes)
                                        .filter(([size, quantity]) => quantity > 0)
                                        .map(([size, quantity]) => (
                                          <div key={size}>
                                            {t("my-order-page.title-18")} {mapShirtSize(size)}{" "}
                                            {t("my-order-page.title-19")} {quantity} {t("my-order-page.title-20")}
                                          </div>
                                        ))
                                    : "-"}
                                </Descriptions.Item>
                              ))}

                              {/* Delivery Status */}
                              <Descriptions.Item
                                label={
                                  <Space>
                                    <WalletOutlined />
                                    <span>{t("my-order-page.title-12")} </span>
                                  </Space>
                                }
                              >
                                {member.totalPrice} {t("my-order-page.title-21")}
                              </Descriptions.Item>
                              <Descriptions.Item
                                label={
                                  <Space>
                                    <TruckOutlined />
                                    <span>{t("my-order-page.title-13")}</span>
                                  </Space>
                                }
                              >
                                {member.delivery ? t("my-order-page.title-22") : t("my-order-page.title-23")}
                              </Descriptions.Item>

                              {/* Payment Status */}
                              <Descriptions.Item
                                label={
                                  <Space>
                                    <CheckOutlined />
                                    <span>{t("my-order-page.title-14")}</span>
                                  </Space>
                                }
                              >
                                {member.status === "paid"
                                  ? t("my-order-page.title-24")
                                  : member.status === "pending"
                                  ? t("my-order-page.title-25")
                                  : member.status === "cancel"
                                  ? t("my-order-page.title-26")
                                  : t("my-order-page.title-27")}
                              </Descriptions.Item>
                            </Descriptions>
                          </Space>
                          <Space justify="end" style={{ marginTop: "16px" }}>
                            {editingMemberId === memberId ? (
                              <>
                                <Button type="primary" loading={loading} onClick={() => handleSave(event.id, memberId)}>
                                  {t("my-order-page.button-2")}
                                </Button>
                                <Button type="default" onClick={() => setEditingMemberId(null)} disabled={loading}>
                                  {t("my-order-page.button-3")}
                                </Button>
                              </>
                            ) : (
                              <Button type="primary" onClick={() => handleEdit(memberId, member)}>
                                {t("my-order-page.button-1")}
                              </Button>
                            )}
                          </Space>
                          <Divider style={{ borderColor: "#130C63" }} />
                        </React.Fragment>
                      ))}
                    </Panel>
                  </Collapse>
                </Card>
              ))
            ) : (
              <Card
                title={t("my-order-page.title-1")}
                extra={
                  <Button onClick={() => navigate(`/${language}/sport-register/run`)}>
                    {t("my-order-page.title-2")} !
                  </Button>
                }
                style={{
                  maxWidth: "400px",
                  margin: "0 auto",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                }}
              >
                {t("my-order-page.title-5")}
              </Card>
            )}
          </Col>
        )}

        <Col span={24}>
          {eventClose?.length > 0 && eventClose[0]?.shirtOrder?.length > 0 ? (
            eventClose.map((event) => (
              <Card
                key={event.id}
                type="inner"
                title={t("my-order-page.title-15")}
                cover={
                  <div style={{ aspectRatio: "16/9", overflow: "hidden" }}>
                    <img src={event.cover} alt={event.title} style={{ width: "100%", height: "100%" }} />
                  </div>
                }
                style={{
                  maxWidth: "768px",
                  margin: "0 auto",
                  marginBottom: "16px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                }}
              >
                <Card.Meta title={event.title} style={{ paddingBottom: "10px" }} />
                <Row>
                  <Col xs={12} sm={7} md={5} lg={10} xl={7} xxl={5}>
                    <Paragraph style={{ margin: 0 }}>
                      <EnvironmentOutlined style={{ color: "red" }} /> {event.location} <CalendarOutlined />{" "}
                      {event.date}
                    </Paragraph>
                  </Col>
                </Row>

                <Collapse style={{ marginTop: "16px" }} expandIconPosition="end">
                  <Panel header={t("my-order-page.title-16")} key="1">
                    {Object.entries(event.shirtOrder).map(([memberId, member]) => (
                      <React.Fragment key={memberId}>
                        <Space direction="vertical" size="large" style={{ width: "100%", marginTop: "16px" }}>
                          <Descriptions layout="vertical" column={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 5 }}>
                            <Descriptions.Item
                              label={
                                <Space>
                                  <CalendarOutlined />
                                  <span>{t("my-order-page.title-6")}</span>
                                </Space>
                              }
                            >
                              {member.date} {member.time}
                            </Descriptions.Item>

                            <Descriptions.Item
                              label={
                                <Space>
                                  <UserOutlined />
                                  <span>{t("my-order-page.title-7")}</span>
                                </Space>
                              }
                            >
                              {member.firstName}
                            </Descriptions.Item>

                            <Descriptions.Item
                              label={
                                <Space>
                                  <UserOutlined />
                                  <span>{t("my-order-page.title-8")}</span>
                                </Space>
                              }
                            >
                              {member.lastName}
                            </Descriptions.Item>

                            {/* Phone Number */}
                            <Descriptions.Item
                              label={
                                <Space>
                                  <PhoneOutlined />
                                  <span>{t("my-order-page.title-9")}</span>
                                </Space>
                              }
                            >
                              {member.phone}
                            </Descriptions.Item>

                            {/* Address (not editable) */}
                            <Descriptions.Item
                              label={
                                <Space>
                                  <HomeOutlined />
                                  <span>{t("my-order-page.title-10")}</span>
                                </Space>
                              }
                            >
                              {member.address === "" ? t("my-order-page.title-17") : member.address}
                            </Descriptions.Item>

                            {/* Shirt Size */}
                            {Object.entries(member.shirtTotal).map(([shirtType, sizes]) => (
                              <Descriptions.Item
                                key={shirtType}
                                label={
                                  <Space>
                                    <SkinOutlined />
                                    <span>
                                      {t("my-order-page.title-11")} ({shirtType.replace("shirt", "").toUpperCase()})
                                    </span>
                                  </Space>
                                }
                              >
                                {Object.entries(sizes)
                                  .filter(([size, quantity]) => quantity > 0)
                                  .map(([size, quantity]) => (
                                    <div key={size}>
                                      {t("my-order-page.title-18")} {mapShirtSize(size)} {t("my-order-page.title-19")}{" "}
                                      {quantity} {t("my-order-page.title-20")}
                                    </div>
                                  )).length > 0
                                  ? Object.entries(sizes)
                                      .filter(([size, quantity]) => quantity > 0)
                                      .map(([size, quantity]) => (
                                        <div key={size}>
                                          {t("my-order-page.title-18")} {mapShirtSize(size)}{" "}
                                          {t("my-order-page.title-19")} {quantity} {t("my-order-page.title-20")}
                                        </div>
                                      ))
                                  : "-"}
                              </Descriptions.Item>
                            ))}

                            {/* Delivery Status */}
                            <Descriptions.Item
                              label={
                                <Space>
                                  <WalletOutlined />
                                  <span>{t("my-order-page.title-12")} </span>
                                </Space>
                              }
                            >
                              {member.totalPrice} {t("my-order-page.title-21")}
                            </Descriptions.Item>
                            <Descriptions.Item
                              label={
                                <Space>
                                  <TruckOutlined />
                                  <span>{t("my-order-page.title-13")}</span>
                                </Space>
                              }
                            >
                              {member.delivery ? t("my-order-page.title-22") : t("my-order-page.title-23")}
                            </Descriptions.Item>

                            {/* Payment Status */}
                            <Descriptions.Item
                              label={
                                <Space>
                                  <CheckOutlined />
                                  <span>{t("my-order-page.title-14")}</span>
                                </Space>
                              }
                            >
                              {member.status === "paid"
                                ? t("my-order-page.title-24")
                                : member.status === "pending"
                                ? t("my-order-page.title-25")
                                : member.status === "cancel"
                                ? t("my-order-page.title-26")
                                : t("my-order-page.title-27")}
                            </Descriptions.Item>
                          </Descriptions>
                        </Space>

                        <Divider style={{ borderColor: "#130C63" }} />
                      </React.Fragment>
                    ))}
                  </Panel>
                </Collapse>
              </Card>
            ))
          ) : (
            <Card
              title="ประวัติการสั่งเสื้อ"
              style={{
                maxWidth: "400px",
                margin: "0 auto",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              }}
            >
              {t("my-order-page.title-5")}
            </Card>
          )}
        </Col>
      </Row>
    </AppLayoutLogin>
  );
};

export default OrderShirtPage;
