import React, { useState } from "react";
import { Row, Col, Image, Typography, Card, Grid, Modal } from "antd";
import MenuButton from "../components/menu-button";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AppLayout from "../layout/app-layout";
import { useTranslation } from "react-i18next";

const { useBreakpoint } = Grid;
const { Title, Paragraph } = Typography;

const Home = ({ user }) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Use useState to manage the Canva embed URL
  // const [show, setShow] = useState("https://www.canva.com/design/DAGSyDEgmIo/bj94pmY67XBHc_NVDfRbBQ/view?embed");
  // const [color, setColor] = useState("#5E72EB");

  return (
    <>
      <AppLayout
        currentPage={t("app-header.title-1")}
        user={user}
        GetIconNav={<HomeOutlined style={{ fontSize: "24px" }} />}
        screens={screens}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Image src="/home-banner.svg" preview={false} width="100%" alt="Main Content Image" />
          </Col>
        </Row>

        {/* Menu Button */}
        <MenuButton screens={screens} navigate={navigate} t={t} />
        <Row justify="center" gutter={[16, 16]} style={{ marginTop: "20px" }}>
          <Col xs={23} sm={23} md={23} lg={23} xl={8} xxl={8}>
            <Card
              hoverable
              size="small"
              style={{ borderRadius: "20px" }}
              onClick={() => (window.location.href = "https://paipao.my.canva.site/travel")}
            >
              <Row justify="center" align="middle">
                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} style={{ textAlign: "center" }}>
                  <Image src="/map-icon.svg" preview={false} alt="Location Recommendation" />
                  <Paragraph strong style={{ margin: 0, color: "#5E72EB" }}>
                    {t("home-page.button-10")}
                  </Paragraph>
                </Col>
                <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16} style={{ textAlign: "center" }}>
                  <Image
                    src="/recommend-place.JPG"
                    preview={false}
                    width="100%"
                    alt="Location Recommendation"
                    style={{ maxWidth: "500px", height: "100%", borderRadius: "30px" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={23} sm={23} md={23} lg={23} xl={8} xxl={8}>
            <Card
              hoverable
              size="small"
              style={{ borderRadius: "20px" }}
              onClick={() => (window.location.href = "https://paipao.my.canva.site/runner")}
            >
              <Row justify="center" align="middle">
                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} style={{ textAlign: "center" }}>
                  <Image src="/calendar-icon.svg" preview={false} alt="Sport Calendar" />
                  <Paragraph strong style={{ margin: 0, color: "#4FB7E5" }}>
                    {t("home-page.button-11")}
                  </Paragraph>
                </Col>
                <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16} style={{ textAlign: "center" }}>
                  <Image
                    src="/recommend-calendar.JPG"
                    preview={false}
                    width="100%"
                    alt="Sport Calendar"
                    style={{ maxWidth: "500px", height: "100%", borderRadius: "30px" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={23} sm={23} md={23} lg={23} xl={8} xxl={8}>
            <Card
              hoverable
              size="small"
              style={{ borderRadius: "20px" }}
              onClick={() => (window.location.href = "https://paipao.my.canva.site/photographer")}
            >
              <Row justify="center" align="middle">
                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} style={{ textAlign: "center" }}>
                  <Image src="/photo-icon.svg" preview={false} alt="Food Promotion" />
                  <Paragraph strong style={{ margin: 0, color: "#70ACB6" }}>
                    {t("home-page.button-12")}
                  </Paragraph>
                </Col>
                <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16} style={{ textAlign: "center" }}>
                  <Image
                    src="/recommend-photo.JPG"
                    preview={false}
                    width="100%"
                    alt="Photo Grapher"
                    style={{ maxWidth: "500px", height: "100%", borderRadius: "30px" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row justify="center" gutter={[16, 16]} style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col xs={23} sm={23} md={23} lg={23} xl={8} xxl={8}>
            <Card
              hoverable
              size="small"
              style={{ borderRadius: "20px" }}
              onClick={() => (window.location.href = "https://paipao.my.canva.site/mutalu")}
            >
              <Row justify="center" align="middle">
                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} style={{ textAlign: "center" }}>
                  <Image src="/mu-icon.svg" preview={false} alt="Location Recommendation" />
                  <Paragraph strong style={{ margin: 0, color: "#4B3221" }}>
                    {t("home-page.button-13")}
                  </Paragraph>
                </Col>
                <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16} style={{ textAlign: "center" }}>
                  <Image
                    src="/recommend-mu.JPG"
                    preview={false}
                    width="100%"
                    alt="Mu Location"
                    style={{ maxWidth: "500px", height: "100%", borderRadius: "30px" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={23} sm={23} md={23} lg={23} xl={8} xxl={8}>
            <Card
              hoverable
              size="small"
              style={{ borderRadius: "20px" }}
              onClick={() => (window.location.href = "https://paipao.my.canva.site/food")}
            >
              <Row justify="center" align="middle">
                <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} style={{ textAlign: "center" }}>
                  <Image src="/food-shop-icon.svg" preview={false} alt="Food Promotion" />
                  <Paragraph strong style={{ margin: 0, color: "#FF9190" }}>
                    {t("home-page.button-14")}
                  </Paragraph>
                </Col>
                <Col xs={12} sm={12} md={16} lg={16} xl={16} xxl={16} style={{ textAlign: "center" }}>
                  <Image
                    src="/recommend-food.JPG"
                    preview={false}
                    width="100%"
                    alt="Food Promotion"
                    style={{ maxWidth: "500px", height: "100%", borderRadius: "30px" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          {/* <Col xs={23} sm={23} md={23} lg={23} xl={8} xxl={8}>
            <Card style={{ borderRadius: "20px" }}>
              <Title level={5} style={{ margin: 0 }}>
                การแจ้งเตือน
              </Title>
              <Paragraph>

              💥 โปรโมชั่นพิเศษ: ลงโฆษณากับเราฟรี! ไม่มีค่าบริการ สำหรับธุรกิจ โรงแรม รถเช่า บริการรถรับส่ง ทัวร์ One Day Trip จุดชาร์จ EV ร้านอาหาร ร้านค้า หรือร้านของฝาก ขนาดเล็กหรือใหญ่ เรารับหมด! โปรโมชั่นนี้มีถึงวันที่ 31 ธันวาคม 2567 เท่านั้น สนใจติดต่อได้ที่ 084-9825463
              </Paragraph>
            </Card>
          </Col> */}
        </Row>

        {/* <div
      style={{
        position: "relative",
        width: "100%",
        height: 0,
        paddingTop: "56.25%", // Adjust the aspect ratio if needed
        paddingBottom: 0,
        boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
        marginTop: "1.6em",
        marginBottom: "0.9em",
        overflow: "hidden",
        borderRadius: "8px",
        willChange: "transform",
      }}
    >
      <iframe
        loading="lazy"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          border: "none",
          padding: 0,
          margin: 0,
        }}
        src="https://paipao.my.canva.site/travel"
        allowFullScreen
        title="Travel Site"
      />
    </div> */}
      </AppLayout>
    </>
  );
};

export default Home;
