import React from "react";
import { Card, Grid } from "antd";
import MenuButton from "../components/menu-button";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AdminLabel from "../components/admin/admin-label";
import AppLayoutAdmin from "../layout/app-layout-admin";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../context/app-provider";

const { useBreakpoint } = Grid;

const HomeAdmin = ({}) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const { language } = useAppContext();
  const { t } = useTranslation();

  const gridStyle = {
    width: "100%",
    textAlign: "start",
    cursor: "pointer",
  };

  return (
    <AppLayoutAdmin currentPage="หน้าแรก" getIconNav={<HomeOutlined style={{ fontSize: "24px" }} />} screens={screens}>
      {/* Menu Button */}
      <AdminLabel t={t} screens={screens} />
      <MenuButton t={t} screens={screens} navigate={navigate} />
      <Card style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
        {/* <Typography.Title>ยินดีต้อนรับคุณ {userInfo?.fullName}</Typography.Title> */}
      </Card>

      {/* <Card title="ส่วนของผู้จัดการ (Manager Home)" size="default" style={{ borderRadius: "20px" }}>
        <Card.Grid style={gridStyle} onClick={() => navigate("ManageEvents")}>
          จัดการข้อมูลกิจกรรม (Manage Events)
        </Card.Grid>
        <Card.Grid style={gridStyle} onClick={() => navigate("/manage-member")}>
          จัดการข้อมูลสมาชิก (Manage Member)
        </Card.Grid>
        <Card.Grid style={gridStyle} onClick={() => navigate("/manage-check-update")}>
          ตรวจสอบและบันทึกสถานะ (Check and Update Status)
        </Card.Grid>
      </Card> */}
    </AppLayoutAdmin>
  );
};

export default HomeAdmin;
