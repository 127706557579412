import React from "react";
import { Layout } from "antd";
import AppHeader from "./app-header";
import AppFooter from "./app-footer";

const { Content } = Layout;

const AppLayoutLogin = ({ children, currentPage, GetIconNav, user, handleNavigate, screens }) => (
  <Layout>
    <AppHeader
      currentPage={currentPage}
      GetIconNav={GetIconNav}
      user={user}
      handleNavigate={handleNavigate}
      screens={screens}
    />
    <Content
      style={{
        padding: screens.xs ? "0px" : "30px",
      }}
    >
      {children}
    </Content>
    <AppFooter />
  </Layout>
);

export default AppLayoutLogin;
